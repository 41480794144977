import { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  TextField,
  Dialog,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link, useParams } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import useFetchCandidate from "hooks/useFetchCandidate";
import useUpdateCandidate from "hooks/useUpdateCandidate";

import { Skeleton } from "@mui/material";

function CandidateProfileSkeleton() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card raised sx={{ maxWidth: 960, mx: "auto", mt: 4, pt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Skeleton
                variant="circular"
                width={128}
                height={128}
                sx={{ mx: "auto", mb: 3 }}
              />
              <Skeleton variant="text" width="80%" sx={{ mx: "auto" }} />
              <Skeleton variant="text" width="60%" sx={{ mx: "auto", mb: 1 }} />
              <Skeleton
                variant="rectangular"
                width="90%"
                height={60}
                sx={{ mx: "auto", mb: 3 }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Skeleton variant="text" width="40%" sx={{ mb: 2 }} />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{ mb: 1 }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{ mb: 1 }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{ mb: 3 }}
              />

              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                sx={{ mb: 2 }}
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                sx={{ mb: 2 }}
              />
              <Skeleton variant="rectangular" width="100%" height={100} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

function CandidateProfileFull() {
  const { id } = useParams();
  const [showAllSkills, setShowAllSkills] = useState(false);
  const { loadingCandidate, candidate } = useFetchCandidate(id);
  const [editCandidate, setEditCandidate] = useState(null);
  const { updateCandidate } = useUpdateCandidate(id);

  useEffect(() => {
    if (!loadingCandidate && candidate) {
      setEditCandidate(candidate); // Initialize editCandidate with fetched candidate data
    }
  }, [loadingCandidate, candidate]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const maxSkillsToShow = 5;

  const handleEditDialogOpen = () => setIsEditDialogOpen(true);
  const handleEditDialogClose = () => setIsEditDialogOpen(false);

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const updatedInfo = {
      location: formData.get("location"),
      phone: formData.get("phone"),
      email: formData.get("email"),
    };

    // Optimistically update the UI
    setEditCandidate({ ...editCandidate, ...updatedInfo });

    try {
      // Try to update the candidate on the server
      await updateCandidate(updatedInfo);

      // If the update fails, it will throw an error, so if we reach this point,
      // it means the update was successful. You might not need to do anything here
      // if your state update above is exactly what you want.
    } catch (error) {
      // If the server update fails, revert the optimistic update
      setEditCandidate(candidate); // Revert to the original candidate data
      console.error("Failed to update candidate:", error);
    }

    handleEditDialogClose();
  };

  const toggleSkillsDisplay = () => {
    setShowAllSkills(!showAllSkills);
  };

  if (loadingCandidate || !editCandidate) {
    return <CandidateProfileSkeleton />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton
        variant="contained"
        color="primary"
        onClick={handleEditDialogOpen}
      >
        Edit Info
      </MDButton>
      <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
        <form onSubmit={handleEditSubmit}>
          <DialogTitle>Edit Candidate Info</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              defaultValue={editCandidate.email}
              name="email"
            />
            <TextField
              margin="dense"
              id="phone"
              label="Phone Number"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={editCandidate.phone}
              name="phone"
            />
            <TextField
              margin="dense"
              id="location"
              label="Location"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={editCandidate.location}
              name="location"
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleEditDialogClose}>Cancel</MDButton>
            <MDButton type="submit">Save</MDButton>
          </DialogActions>
        </form>
      </Dialog>
      <Card raised sx={{ maxWidth: 960, mx: "auto", mt: 4, pt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Avatar
                sx={{ width: 128, height: 128, mx: "auto", mb: 3 }}
                src="/ananya-grover.jpg"
                alt={editCandidate.name}
              />
              <Typography variant="h5" fontWeight="medium" align="center">
                {editCandidate.name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                align="center"
                style={{ marginBottom: "1em" }}
              >
                {editCandidate.currentJob}
              </Typography>
              {/* <Typography variant="body2" sx={{ mt: 2, mb: 3 }}>
                Full stack product designer with hands-on experience in solving
                problems...
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  "& > *": { m: 0.5 },
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {editCandidate.skills != null &&
                    editCandidate.skills
                      .slice(
                        0,
                        showAllSkills
                          ? editCandidate.skills.length
                          : maxSkillsToShow
                      )
                      .map((skill) => (
                        <Chip
                          sx={{ m: 0.5 }}
                          key={skill.id}
                          label={skill.value}
                        />
                      ))}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {editCandidate.skill != null &&
                      editCandidate.skills.length > maxSkillsToShow && (
                        <MDButton
                          variant="gradient"
                          color="success"
                          size="small"
                          onClick={toggleSkillsDisplay}
                          sx={{
                            marginTop: "1em",
                          }}
                        >
                          {showAllSkills
                            ? "Show Less Skills"
                            : "Show More Skills"}
                        </MDButton>
                      )}
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Basic Information
                  </Typography>
                  {/* <Typography variant="body2">
                    <strong>Age:</strong> 28 years
                  </Typography> */}
                  {/* <Typography variant="body2">
                    <strong>Years of Experience:</strong>{" "}
                    {editCandidate.yearsExperience}
                  </Typography> */}
                  <Typography variant="body2">
                    <strong>Location:</strong> {editCandidate.location}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Contact
                  </Typography>
                  <Typography variant="body2">
                    <strong>Phone:</strong>
                    {editCandidate.phone}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Email:</strong> {editCandidate.email}
                  </Typography>
                  <IconButton color="primary">
                    <a href={`mailto:${editCandidate.email}`}>
                      <EmailIcon />
                    </a>
                  </IconButton>
                  <IconButton color="primary">
                    <a href={`tel:${editCandidate.phone}`}>
                      <PhoneIcon />
                    </a>
                  </IconButton>
                </Box>
              </Box>
              <Accordion sx={{ mb: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Clearance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemText
                        // disableTypography
                        primary={
                          <Typography variant="body2">
                            {editCandidate.clearanceLevel}
                          </Typography>
                        }
                        // primary={question}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ mb: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Education</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            {editCandidate.educationLevel}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Certifications</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {editCandidate.certifications?.map((item) => (
                    <ListItem key={item}>
                      <ListItemText
                        primary={
                          <Typography variant="body2">{item}</Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default CandidateProfileFull;
