// NotificationManager.tsx

import React, { useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import { Notification, useNotifications } from "./NotificationsContext";
import { Fade } from "@mui/material";

const NOTIFICATION_TIMEOUT_IN_SECONDS = 5000;

const NotificationManager: React.FC = () => {
  const { notifications, removeNotification } = useNotifications();

  useEffect(() => {
    const timers: NodeJS.Timeout[] = notifications.map(
      (notification) =>
        setTimeout(() => {
          removeNotification(notification.id);
        }, NOTIFICATION_TIMEOUT_IN_SECONDS) // Automatically remove after 5 seconds
    );

    return () => {
      timers.forEach(clearTimeout);
    };
  }, [notifications, removeNotification]);

  return (
    <>
      {notifications.map((notification: Notification) => (
        <MDSnackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={notification.id.toString()} // Ensuring key is a string
          color={notification.color}
          icon={notification.icon}
          title={notification.title}
          content={notification.content}
          dateTime={notification.dateTime}
          TransitionComponent={Fade}
          open={true}
          onClose={() => removeNotification(notification.id)}
          close={() => removeNotification(notification.id)}
          bgWhite={notification.bgWhite}
        />
      ))}
    </>
  );
};

export default NotificationManager;
