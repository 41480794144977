import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const JobPost = {
  get: async function (jobId, cancel = false, token) {
    const response = await api.request({
      url: `/job-post/${jobId}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data[0];
  },
  create: async function (jobPostData, cancel = false, token) {
    const response = await api.request({
      url: "/job-post",
      method: "POST",
      data: jobPostData,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
  updateJobStatus: async function (
    jobId,
    jobStatusData,
    cancel = false,
    token
  ) {
    const response = await api.request({
      url: `/job-post/${jobId}/change-status`, // Assuming a specific endpoint for status updates
      method: "PUT", // Using PATCH or PUT depending on your API design for updates
      data: jobStatusData,
      signal: cancel
        ? cancelApiObject[this.updateJobStatus.name].handleRequestCancellation()
            .signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
  update: async function (jobId, jobData, cancel = false, token) {
    const response = await api.request({
      url: `/job-post/${jobId}`,
      method: "PUT",
      data: jobData,
      signal: cancel
        ? cancelApiObject[this.updateJobStatus.name].handleRequestCancellation()
            .signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
  delete: async function (jobId, cancel = false, token) {
    const response = await api.request({
      url: `/job-post/${jobId}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data[0];
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(JobPost);
