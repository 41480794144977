import { useEffect, useState } from "react";
import { Scores } from "apis/Score";

import { useAuth0 } from "@auth0/auth0-react";

const useFetchScores = ({ jobId }) => {
  const [jobScoreData, setJobScoreData] = useState(null);
  const [loadingScores, setLoadingScores] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchJobPosts = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoadingScores(true);
        const response = await Scores.get(jobId, false, token);

        if (isMounted) {
          setJobScoreData(response);
          setLoadingScores(false);
        }
      } catch (error) {
        setLoadingScores(false);
        console.error("Error fetching scores:", error);
      }
    };

    fetchJobPosts();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loadingScores, jobScoreData };
};

export default useFetchScores;
