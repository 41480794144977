import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AllJobsTable from "examples/Tables/DataTable/Jobs/JobsDataTable";
import useFetchJobPostsForTable from "hooks/useFetchJobsPostForTable";

function AllJobsPage(): JSX.Element {
  const { loading, tableData } = useFetchJobPostsForTable();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Job Posts
              </MDTypography>
              <MDTypography variant="button" color="text">
                Current Job Posts
              </MDTypography>
            </MDBox>
            <AllJobsTable table={tableData} isLoading={loading} canSearch />
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AllJobsPage;
