import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const Teams = {
  getMember: async function (id, cancel = false, token) {
    const response = await api.request({
      url: `/team/${id}/members`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getMember.name].handleRequestCancellation()
            .signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(Teams);
