import { Scores } from "apis/Score";
import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

const useFetchScoreForJobPost = ({ jobId, candidateId }) => {
  const [candidateWithScore, setCandidateWithScore] = useState(null);
  const [loadingScoreForJobPost, setLoadingScoreForJobPost] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchJobPosts = async () => {
      const token = await getAccessTokenSilently();

      try {
        setLoadingScoreForJobPost(true);
        const response = await Scores.getCandidateAndJob(
          jobId,
          candidateId,
          false,
          token
        );

        if (isMounted) {
          setCandidateWithScore(response);
          setLoadingScoreForJobPost(false);
        }
      } catch (error) {
        setLoadingScoreForJobPost(false);
        console.error("Error fetching scores:", error);
      }
    };

    fetchJobPosts();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loadingScoreForJobPost, candidateWithScore };
};

export default useFetchScoreForJobPost;
