import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart, {
  ChartTypes,
} from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import SalesTable from "examples/Tables/SalesTable";
import LatestJobsTable from "examples/Tables/DataTable/LatestJobs/LatestJobsTable";

// Sales dashboard components
import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";

// Data
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
import salesTableData from "layouts/dashboards/sales/data/salesTableData";
import dataTableData from "layouts/dashboards/sales/data/dataTableData";
import useFetchJobPostsForDashboard from "hooks/useFetchLatestJobPostsForDashboard";
import useFetchDashboardAnalytics from "hooks/useFetchDashboard";
import { Skeleton } from "@mui/material";

function FormatLineChart(data) {
  const lineChartData: ChartTypes = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: "Quantity",
        color: "info",
        data: data.map((item) => item.quantity),
      },
    ],
  };
  return lineChartData;
}

function Sales(): JSX.Element {
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] =
    useState<string>("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] =
    useState<string>("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] =
    useState<string>("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState<string | null>(null);
  const [customersDropdown, setCustomersDropdown] = useState<string | null>(
    null
  );
  const [revenueDropdown, setRevenueDropdown] = useState<string | null>(null);

  const { latestJobPostsLoading, tableData } = useFetchJobPostsForDashboard();
  const { dashboardAnalytics, loadingDashboardAnalytics } =
    useFetchDashboardAnalytics();

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }: any) =>
    setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }: any) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }: any) =>
    setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }: any) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }: any) =>
    setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }: any) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state: any, close: any) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  if (latestJobPostsLoading || !dashboardAnalytics) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              {/* Skeletons for each card */}
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rectangular" height={100} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rectangular" height={100} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rectangular" height={100} />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              {/* Skeleton for the line chart */}
              <Grid item xs={12} sm={6} lg={4}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
              <Grid item xs={12} sm={6} lg={8}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={3} px={3}>
                  <Skeleton variant="text" width="20%" />
                </MDBox>
                <MDBox py={1}>
                  {/* Skeleton for the table */}
                  <Skeleton variant="rectangular" height={400} />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="sales"
                count="$230,220"
                percentage={{
                  color: "success",
                  value: "+55%",
                  label: "since last month",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="customers"
                count="3.200"
                percentage={{
                  color: "success",
                  value: "+12%",
                  label: "since last month",
                }}
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="avg. revenue"
                count="$1.200"
                percentage={{
                  color: "secondary",
                  value: "+$213",
                  label: "since last month",
                }}
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              />
            </Grid>
          </Grid>
        </MDBox> */}
        {!loadingDashboardAnalytics && (
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={4}>
                <ChannelsChart data={dashboardAnalytics["candidateSource"]} />
              </Grid>
              <Grid item xs={12} sm={6} lg={8}>
                <DefaultLineChart
                  title="Candidates Scored"
                  description={
                    <MDBox display="flex" justifyContent="space-between">
                      <MDBox display="flex" ml={-1}>
                        {/* <MDBadgeDot color="info" size="sm" badgeContent="New" /> */}
                        {/* <MDBadgeDot
                          color="dark"
                          size="sm"
                          badgeContent="Re-Processed"
                        /> */}
                      </MDBox>
                      <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                        <Tooltip
                          title="Total Candidates Scored Across all Channels"
                          placement="left"
                          arrow
                        >
                          <MDButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            circular
                            iconOnly
                          >
                            <Icon>priority_high</Icon>
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                  }
                  chart={FormatLineChart(dashboardAnalytics.scoredCandidates)}
                />
              </Grid>
            </Grid>
          </MDBox>
        )}
        {/* <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <HorizontalBarChart
                title="Sales by age"
                chart={horizontalBarChartData}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <SalesTable title="Sales by Country" rows={salesTableData} />
            </Grid>
          </Grid>
        </MDBox> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Latest Jobs
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <LatestJobsTable
                  table={tableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                  isLoading={latestJobPostsLoading}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Sales;
