import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable/Candidates/CandidatesDataTable";
import useFetchCandidatesForTable from "hooks/useFetchCandidatesForTable";

function DataTables(): JSX.Element {
  const { loading, tableData } = useFetchCandidatesForTable();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Candidate List
            </MDTypography>
            <MDTypography variant="button" color="text">
              Master List of Candidates
            </MDTypography>
          </MDBox>
          <DataTable table={tableData} canSearch isLoading={loading} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default DataTables;
