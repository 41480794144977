import { useEffect, useState } from "react";
import { Candidate } from "apis/Candidate";

import { useAuth0 } from "@auth0/auth0-react";

const useFetchCandidate = (id) => {
  const [candidate, setCandidateData] = useState(null);
  const [loadingCandidate, setLoadingCandidate] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchCandidates = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoadingCandidate(true);
        const candidate = await Candidate.get(id, false, token);

        if (isMounted) {
          setCandidateData((prevState) => candidate);
          setLoadingCandidate(false);
        }
      } catch (error) {
        setLoadingCandidate(false);
        console.error("Error fetching job post", error);
      }
    };

    fetchCandidates();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, [id, getAccessTokenSilently]); // Add dependencies here if needed

  return { loadingCandidate, candidate };
};

export default useFetchCandidate;
