import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const User = {
  getUser: async function (id, cancel = false, token) {
    const response = await api.request({
      url: `/user-profile/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.updateUser.name].handleRequestCancellation()
            .signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
  updateUser: async function (id, data, cancel = false, token) {
    const response = await api.request({
      url: `/user-profile/${id}`,
      method: "PUT",
      data,
      signal: cancel
        ? cancelApiObject[this.updateUser.name].handleRequestCancellation()
            .signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(User);
