import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Teams } from "apis/Teams";

const useFetchTeamMembers = ({ userId }) => {
  const [teamMembers, setTeamMembers] = useState(null);
  const [loadingTeams, setLoadingTeams] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchTeamMembers = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoadingTeams(true);
        const response = await Teams.getMember(userId, false, token);

        if (isMounted) {
          setTeamMembers(response);
          setLoadingTeams(false);
        }
      } catch (error) {
        setLoadingTeams(false);
        console.error("Error fetching Team:", error);
      }
    };

    fetchTeamMembers();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loadingTeams, teamMembers };
};

export default useFetchTeamMembers;
