import React, { useState } from "react";
import { TextField, Button, Box, Typography, Card } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";

function LinkedInSearchForm() {
  const [searchParams, setSearchParams] = useState({
    keywords: "",
    jobTitle: "",
    companyName: "",
    location: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const baseLinkedInSearchURL =
      "https://www.linkedin.com/search/results/people/";
    const queryParts = [];
    if (searchParams.keywords)
      queryParts.push(`keywords=${encodeURIComponent(searchParams.keywords)}`);
    if (searchParams.jobTitle)
      queryParts.push(`title=${encodeURIComponent(searchParams.jobTitle)}`);
    if (searchParams.companyName)
      queryParts.push(
        `company=${encodeURIComponent(searchParams.companyName)}`
      );
    if (searchParams.location)
      queryParts.push(`location=${encodeURIComponent(searchParams.location)}`);

    const linkedInSearchURL = `${baseLinkedInSearchURL}?${queryParts.join(
      "&"
    )}`;

    window.location.href = linkedInSearchURL;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Keywords"
            variant="outlined"
            id="keywords"
            name="keywords"
            value={searchParams.keywords}
            onChange={handleInputChange}
          />
          <TextField
            label="Job Title"
            variant="outlined"
            id="jobTitle"
            name="jobTitle"
            value={searchParams.jobTitle}
            onChange={handleInputChange}
          />
          <TextField
            label="Company Name"
            variant="outlined"
            id="companyName"
            name="companyName"
            value={searchParams.companyName}
            onChange={handleInputChange}
          />
          <TextField
            label="Location"
            variant="outlined"
            id="location"
            name="location"
            value={searchParams.location}
            onChange={handleInputChange}
          />
          <Box sx={{ m: 1 }}>
            <MDButton variant="gradient" color="dark" type="submit">
              Search
            </MDButton>
          </Box>
        </Box>
      </Card>
    </DashboardLayout>
  );
}

export default LinkedInSearchForm;
