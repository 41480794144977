import { useEffect, useState } from "react";
import { Candidates } from "apis/Candidates";

import { useAuth0 } from "@auth0/auth0-react";

const useFetchCandidates = () => {
  const [candidates, setCandidatesData] = useState(null);
  const [loadingCandidates, setLoadingCandidates] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchCandidates = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoadingCandidates(true);
        const candidates = await Candidates.get(false, token);

        if (isMounted) {
          setCandidatesData((prevState) =>
            prevState ? [...prevState, ...candidates] : [...candidates]
          );
          setLoadingCandidates(false);
        }
      } catch (error) {
        setLoadingCandidates(false);
        console.error("Error fetching job post", error);
      }
    };

    fetchCandidates();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loadingCandidates, candidates };
};

export default useFetchCandidates;
