import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Skills } from "apis/Skills";

const useFetchSkills = () => {
  const [skills, setSkills] = useState(null);
  const [loadingSkills, setLoadingSkills] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchJobPosts = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoadingSkills(true);
        const response = await Skills.get(false, token);

        if (isMounted) {
          setSkills(response);
          setLoadingSkills(false);
        }
      } catch (error) {
        setLoadingSkills(false);
        console.error("Error fetching scores:", error);
      }
    };

    fetchJobPosts();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loadingSkills, skills, setSkills };
};

export default useFetchSkills;
