import { JobPosts } from "apis/JobPosts";
import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

const tableColumns = [
  { Header: "Job Title", accessor: "jobTitle" },
  { Header: "Company", accessor: "company" },
  { Header: "Location", accessor: "location" },
];

const useFetchJobPostsForTable = () => {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchJobPosts = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoading(true);
        const jobPosts = await JobPosts.get(false, token);
        const rows = jobPosts.map((job) => ({
          id: job.id,
          jobTitle: job.jobTitle,
          company: job.company,
          location: job.location,
        }));

        if (isMounted) {
          setTableData((prevState) => ({
            ...prevState,
            columns: tableColumns,
            rows,
          }));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching job posts:", error);
      }
    };

    fetchJobPosts();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loading, tableData };
};

export default useFetchJobPostsForTable;
