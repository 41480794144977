import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  Grid,
  Skeleton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import {
  useFetchJobPost,
  useFetchResume,
  useFetchScoreForJobPost,
  useFetchScores,
} from "hooks";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const CandidateJobComparison = () => {
  const { jobId } = useParams(); // This hook gets the params from the URL
  const { candidateId } = useParams(); // This hook gets the params from the URL

  // const { candidate } = useFetchCandidate(candidateId);
  const { jobScoreData } = useFetchScores({ jobId });
  const { jobData } = useFetchJobPost({ jobId });
  const { candidateWithScore } = useFetchScoreForJobPost({
    jobId,
    candidateId,
  });

  const { data, pdfFile, htmlContent } = useFetchResume(candidateId);

  const isLoading = !jobScoreData || !jobData || !candidateWithScore || !data;

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Card>
          <MDBox p={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item md={4} sm={12}>
                <Skeleton variant="rectangular" width={210} height={118} />
                <Skeleton width="60%" />
                <Skeleton width="60%" />
              </Grid>
              <Grid item md={4} sm={12}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton width="80%" />
              </Grid>
              <Grid item md={4} sm={12}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
        <Card raised sx={{ mx: "auto", mt: 4, pt: 3 }}>
          <CardContent>
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="rectangular" width="100%" height={118} />
          </CardContent>
        </Card>
        {/* Repeat for other sections as needed */}
      </DashboardLayout>
    );
  }

  const score = candidateWithScore.score;
  const isQualified = score && score >= 80; // Define qualification criteria

  const uniqueJobSkills = new Set(
    jobData.primarySkills.map((skill) => skill.value)
  );

  const matchedSkills = candidateWithScore.skills.filter(
    (skill, index, self) =>
      uniqueJobSkills.has(skill.value) &&
      self.findIndex((s) => s.value === skill.value) === index
  );

  console.log("uniqueJobSkills");
  console.log(uniqueJobSkills);

  console.log("matchedSkills");
  console.log(matchedSkills);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="profile">
        <MDBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={4} sm={12}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {candidateWithScore.name}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="medium">
                  {candidateWithScore.currentJob}
                </MDTypography>
                <br />
                <MDTypography variant="button" color="text" fontWeight="light">
                  Location: {candidateWithScore.location}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item md={4} sm={12}>
              <Box sx={{ mt: 0.5, textAlign: "center" }}>
                <Typography variant="h5" fontWeight="medium">
                  Candidate Score
                </Typography>

                <Box
                  sx={{ display: "inline-flex", position: "relative", mb: 1 }}
                >
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      color="text.secondary"
                    >
                      {`${Math.round(score)}%`}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Box>
              </Box>
            </Grid>
            <Grid item md={4} sm={12}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isQualified ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <ErrorIcon color="error" />
                  )}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {isQualified ? "Qualified" : "Not Qualified"}
                  </Typography>
                </Box>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}></Grid>
          </Grid>
        </MDBox>
      </Card>
      <Card raised sx={{ mx: "auto", mt: 4, pt: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            {/* Candidate Information */}
            <Grid item xs={12} md={6}>
              <Typography variant="h5" component="h2" gutterBottom>
                Summary
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">
                  {candidateWithScore.aiSummary}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h5" component="h2" gutterBottom>
                Candidate Information
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" fontWeight="medium">
                  Years of Experience:{" "}
                  <Typography
                    variant="body2"
                    fontWeight="normal"
                    style={{ display: "inline" }}
                  >
                    {candidateWithScore.yearsExperience}
                  </Typography>
                </Typography>

                {matchedSkills.length !== 0 && (
                  <Typography variant="body2" fontWeight="medium">
                    Matched Skills:
                    <Typography
                      variant="body2"
                      fontWeight="normal"
                      style={{ display: "inline" }}
                    >
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {matchedSkills.map((skill) => (
                          <Chip
                            key={skill.id}
                            label={skill.value}
                            color="primary"
                            sx={{
                              ml: 1,
                              bgcolor: "success.main",
                              color: "white",
                            }}
                          />
                        ))}
                      </Box>
                    </Typography>
                  </Typography>
                )}

                <Typography variant="body2" fontWeight="medium">
                  Missing Requirements:{" "}
                  <Typography
                    variant="body2"
                    fontWeight="normal"
                    style={{ display: "inline" }}
                  >
                    {candidateWithScore.missingRequirements
                      .split(",")
                      .join(", ")}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {pdfFile && (
        <Card raised sx={{ mx: "auto", mt: 4, pt: 3 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Resume
            </Typography>
            <iframe
              title="PDF Content"
              src={pdfFile}
              width="100%"
              height="600px"
              style={{ border: "none" }}
            />
          </CardContent>
        </Card>
      )}

      {htmlContent && (
        <Card raised sx={{ mx: "auto", mt: 4, pt: 3 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Resume
            </Typography>
            <div>
              <iframe
                src={htmlContent}
                title="HTML Content"
                width="100%"
                height="600px"
                style={{ border: "none" }}
              ></iframe>
            </div>
          </CardContent>
        </Card>
      )}
    </DashboardLayout>
  );
};

export default CandidateJobComparison;
