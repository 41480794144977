import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const Candidates = {
  get: async function (cancel = false, token) {
    const response = await api.request({
      url: `/candidates`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(Candidates);
