import { useEffect, useState } from "react";
import { JobPost } from "apis/JobPost";

import { useAuth0 } from "@auth0/auth0-react";

const useFetchJobPost = ({ jobId }) => {
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  
  const fetchJobPosts = async () => {
    try {
      const token = await getAccessTokenSilently();

      setLoading(true);
      const jobPost = await JobPost.get(jobId, false, token);

      setJobData((prevState) => ({
        ...prevState,
        ...jobPost,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching job post", error);
    }
  };
  useEffect(() => {

    fetchJobPosts();
  }, [jobId]); // Add dependencies here if needed

  return { loading, jobData, refetch: fetchJobPosts };
};

export default useFetchJobPost;
