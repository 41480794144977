import { Grid } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function ZohoAuth(): JSX.Element {
  //   const { loading, tableData } = useFetchJobPostsForTable();
  const handleAuth = () => {
    const clientId = process.env.REACT_APP_ZOHO_CLIENT_ID;
    const redirectUri = "https://beta.talentstar.ai/zoho-inbound";
    const scope = "ZohoRecruit.modules.ALL,ZohoRecruit.settings.ALL"; // This scope can change based on your requirements

    window.location.href = `https://accounts.zoho.com/oauth/v2/auth?scope=${encodeURIComponent(
      scope
    )}&client_id=${clientId}&response_type=token&access_type=offline&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <button onClick={handleAuth}>Connect to Zoho</button>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default ZohoAuth;
