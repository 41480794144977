import { Link } from "@mui/material";
import MDButton from "./MDButton";

const useStyles = {
  position: "fixed",
  bottom: 16,
  right: 16,
  color: "white",
};

function FeedbackButton() {
  // Replace "yourLinkHere" with the URL you want the button to link to
  const yourLinkHere =
    "https://form.asana.com/?k=hAwKab3QBpd6NPbpGa6xXg&d=1203954259969041";

  return (
    <Link
      href={yourLinkHere}
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      <MDButton variant="gradient" color="primary" sx={useStyles}>
        Report Bug
      </MDButton>
    </Link>
  );
}

export default FeedbackButton;
