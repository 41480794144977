import { Chip, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

import colors from "assets/theme/base/colors";
import useUpdateJobPostStatus from "hooks/useUpdateJobPostStatus";

export function StatusChip({ currentStatus, onUpdate }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (status) => {
    onUpdate(status);
    handleClose();
  };

  return (
    <div>
      <Chip
        label={currentStatus ? "Open" : "Closed"}
        onClick={handleClick}
        sx={{
          bgcolor: currentStatus ? colors.success.main : colors.warning.main,
          color: "#fff",
          cursor: "pointer",
        }}
      />
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect(true)}>Open</MenuItem>
        <MenuItem onClick={() => handleSelect(false)}>Closed</MenuItem>
      </Menu>
    </div>
  );
}
