import { createRoot } from 'react-dom/client'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import App from 'App'

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from 'context'
import { WizardProvider } from 'context/AddJobWizard'
import { Auth0Provider } from '@auth0/auth0-react'
import { AUTH_0_ISSUER_BASE_URL, CLIENT_ID } from 'utils'
const root = createRoot(document.getElementById('root'))

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate()
  const onRedirectCallback = appState => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }
  return (
    // @ts-ignore
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}

console.log('window.location.origin', window.location.origin)
root.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={AUTH_0_ISSUER_BASE_URL}
      clientId={CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        useRefreshTokensFallback: true,
        audience: `https://${AUTH_0_ISSUER_BASE_URL}/api/v2/`
      }}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation='localstorage'
    >
      <MaterialUIControllerProvider>
        <WizardProvider>
          <App />
        </WizardProvider>
      </MaterialUIControllerProvider>
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>
)
