import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { useWizardContext } from "context/AddJobWizard";
import { useNotifications } from "context/Notifications/NotificationsContext";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import StepNavigation from "../components/StepNavigation";
import colors from "assets/theme/base/colors";

enum ModalLoading {
  Loading = "loading",
  Success = "success",
  Error = "error",
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ReviewAndSubmit({
  setActiveStep,
  activeStep,
  isLastStep,
}): JSX.Element {
  const { jobPostData, resetJobPostData, handleSubmitJobPostData } =
    useWizardContext();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submissionState, setSubmissionState] = useState("");

  const { addNotification } = useNotifications();

  const onSubmit = async () => {
    setIsModalOpen(true);
    setSubmissionState(ModalLoading.Loading);

    try {
      const jobId = await handleSubmitJobPostData();
      setSubmissionState(ModalLoading.Success);

      resetJobPostData();
      addNotification({
        id: new Date().getTime(),
        color: "success",
        icon: "check",
        title: "Job Created Success",
        content: "Redirecting",
        dateTime: "Just now",
        bgWhite: true,
      });
      setTimeout(() => {
        navigate(`/jobs/${jobId}`);
      }, 2000);
    } catch (error) {
      console.error(error);
      setSubmissionState(ModalLoading.Error);
    }
  };

  const handleLocalBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleLocalNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <MDTypography variant="h3">Review and Submit</MDTypography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <MDBox p={3} lineHeight={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="h1" gutterBottom>
              Company Name: {jobPostData.company} ({jobPostData.clearanceLevel})
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Job Title: {jobPostData.jobTitle}
            </Typography>

            {/* Job description and other details */}
            <Divider sx={{ my: 2 }} />

            <Typography variant="h5" gutterBottom>
              Job Description
            </Typography>
            <Typography variant="body2" paragraph>
              {jobPostData.description}
            </Typography>

            <Divider />

            <Typography variant="h5">Screening Questions</Typography>
            <List>
              {jobPostData.screeningQuestions?.map((question, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <Typography variant="body2">{question}</Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
              {/* About Client Section */}
              <Typography variant="h4" gutterBottom>
                Requirements
              </Typography>
              <Box sx={{ display: "block", mb: 2 }}>
                <Typography variant="body2" component="span">
                  <Box component="span" fontWeight="fontWeightBold">
                    Location:{" "}
                  </Box>
                  {jobPostData.location}
                </Typography>
              </Box>
              <Box sx={{ display: "block", mb: 2 }}>
                <Typography variant="body2" component="span">
                  <Box component="span" fontWeight="fontWeightBold">
                    Min Exp:{" "}
                  </Box>
                  {jobPostData.yearsExperience}
                </Typography>
              </Box>
              <Box sx={{ display: "block", mb: 2 }}>
                <Typography variant="body2" component="span">
                  <Box component="span" fontWeight="fontWeightBold">
                    Education:{" "}
                  </Box>
                  {jobPostData.educationRequirements}
                </Typography>
              </Box>
              <Box sx={{ display: "block", mb: 2 }}>
                <Typography variant="body2" component="span">
                  <Box component="span" fontWeight="fontWeightBold">
                    Salary Range:{" "}
                  </Box>
                  {jobPostData.salaryRange}($)
                </Typography>
              </Box>

              <Divider sx={{ my: 2 }} />

              {/* Skills Section */}
              <Typography variant="h6" gutterBottom>
                Required Skills
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  mb: 2,
                }}
              >
                {jobPostData.primarySkills?.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill.value}
                    sx={{
                      bgcolor: colors.primarySkill.main,
                      color: "white",
                    }}
                    color="primary"
                  />
                ))}
              </Box>

              <Typography variant="h6" gutterBottom>
                Highly Desired Skills
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  mb: 2,
                }}
              >
                {jobPostData.secondarySkills?.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill.value}
                    sx={{
                      bgcolor: colors.secondarySkill.main,
                      color: "white",
                    }}
                    color="primary"
                  />
                ))}
              </Box>

              <Typography variant="h6" gutterBottom>
                Nice to have Skills
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  mb: 2,
                }}
              >
                {jobPostData.tertiarySkills?.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill.value}
                    sx={{
                      bgcolor: colors.tertiarySkill.main,
                    }}
                    color="primary"
                  />
                ))}
              </Box>

              <Divider sx={{ my: 2 }} />

              {/* Certifications Section */}
              <Typography variant="h6" gutterBottom>
                Certifications
              </Typography>
              <List sx={{ mb: 2 }}>
                {jobPostData.certifications?.map((cert, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={<Typography variant="body2">{cert}</Typography>}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Divider sx={{ my: 2 }} />
          <StepNavigation
            activeStep={activeStep}
            handleLocalNext={handleLocalNext}
            onBack={handleLocalBack}
            isLastStep={isLastStep}
            onSubmit={onSubmit}
          />
        </Box>
      </MDBox>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="job-submission-modal"
        aria-describedby="job-submission-modal-description"
      >
        <Box sx={modalStyle}>
          {submissionState === ModalLoading.Loading && (
            <>
              <CircularProgress />
              <p>Submitting...</p>
            </>
          )}
          {submissionState === ModalLoading.Success && (
            <Alert severity="success">Done</Alert>
          )}
          {submissionState === ModalLoading.Error && (
            <Alert severity="error">
              There has been an error posting your job, please try again.
            </Alert>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ReviewAndSubmit;
