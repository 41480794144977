import { useEffect } from "react";

export const getAccessTokenFromCookie = () => {
  const name = "accessToken=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const ZohoAuthCallback = () => {
  const setAccessTokenCookie = (accessToken) => {
    const now = new Date();
    now.setTime(now.getTime() + 1 * 3600 * 1000); // Set the cookie to expire in 1 hour
    const expires = "expires=" + now.toUTCString();
    document.cookie = "accessToken=" + accessToken + ";" + expires + ";path=/";
  };

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    const result = hash.split("&").reduce(function (res, item) {
      const parts = item.split("=");
      res[parts[0]] = parts[1];
      return res;
    }, {});

    console.log("Extracted Data from URL fragment:");
    console.log(result);

    // @ts-ignore
    if (result.access_token) {
      // @ts-ignore
      console.log("Access Token:", result.access_token);
      // Store the access token for future use
      // @ts-ignore
      setAccessTokenCookie(result.access_token);

      // Optional: Redirect user away from the callback URL to clean up the URL
      window.history.pushState("", document.title, window.location.pathname);
      window.location.href = "/submit-job";
    }
  }, []);

  return <div>Authorizing Zoho...</div>;
};
