import { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";

import { useWizardContext } from "context/AddJobWizard";
import StepNavigation from "../components/StepNavigation";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import {
  Autocomplete,
  FormHelperText,
  TextField,
  createFilterOptions,
} from "@mui/material";
import useFetchSkills from "hooks/useFetchSkills";

function JobRequirements({ onNext, onBack, activeStep }): JSX.Element {
  const { jobPostData } = useWizardContext();

  const [localData, setLocalData] = useState({
    primarySkills: jobPostData.primarySkills || [],
    secondarySkills: jobPostData.secondarySkills || [],
    tertiarySkills: jobPostData.tertiarySkills || [],
  });

  // State for error messages
  const [errors, setErrors] = useState({ primarySkills: "" });

  const { loadingSkills, skills, setSkills } = useFetchSkills();

  useEffect(() => {
    // Update localData state when skills change
    setLocalData((prevData) => {
      const updateSkills = (skills) =>
        skills.map((skill) => {
          return skills.find((option) => option.value === skill.value) || skill;
        });

      return {
        primarySkills: updateSkills(prevData.primarySkills),
        secondarySkills: updateSkills(prevData.secondarySkills),
        tertiarySkills: updateSkills(prevData.tertiarySkills),
      };
    });
  }, [skills]);

  const filter = createFilterOptions();

  const addNewSkill = (skillValue) => {
    let existingSkill = skills.find((s) => s.value === skillValue);
    if (!existingSkill) {
      const newId =
        skills.length > 0 ? Math.max(...skills.map((s) => s.id)) + 1 : 1;
      existingSkill = { id: newId, value: skillValue };
      setSkills((prevOptions) => [...prevOptions, existingSkill]);
    }
    return existingSkill;
  };

  const handleChange = (skillType, newValue, reason) => {
    if (reason === "selectOption" || reason === "createOption") {
      const updatedSkills = newValue.map((skill) => {
        // Handle both strings and objects uniformly
        return typeof skill === "string"
          ? addNewSkill(skill)
          : skill.inputValue
          ? addNewSkill(skill.inputValue)
          : skill;
      });
      setLocalData((prevData) => ({
        ...prevData,
        [skillType]: updatedSkills,
      }));
    } else {
      setLocalData((prevData) => ({
        ...prevData,
        [skillType]: newValue,
      }));
    }
  };

  const handleLocalBack = useCallback(() => {
    onBack(localData);
  }, [onBack, localData]);

  const handleLocalNext = useCallback(() => {
    // Validation function for Primary Skills
    const validatePrimarySkills = () => {
      if (localData.primarySkills.length === 0) {
        setErrors({ primarySkills: "At least one primary skill is required" });
        return false;
      }
      setErrors({ primarySkills: "" });
      return true;
    };
    if (validatePrimarySkills()) {
      onNext(localData);
    }
  }, [onNext, localData]);

  const renderSkillSelect = (
    skillType: "primarySkills" | "secondarySkills" | "tertiarySkills",
    label: string
  ) => (
    <Grid item xs={12}>
      <FormControl
        fullWidth
        error={skillType === "primarySkills" && !!errors.primarySkills}
        sx={{ marginBottom: "1em" }}
      >
        <Autocomplete
          multiple
          disabled={loadingSkills}
          id={`combo-box-${skillType}`}
          options={skills}
          getOptionLabel={(option: any) => option.value || ""}
          value={localData[skillType]}
          onChange={(event, newValue, reason) =>
            handleChange(skillType, newValue, reason)
          }
          groupBy={(option) => option.firstLetter}
          renderInput={(params) => <TextField {...params} label={label} />}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;

            // Check if the inputValue matches exactly an existing option
            const isExisting = options.some(
              (option) => inputValue === option.value
            );

            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                value: `Add ${inputValue}`, // Directly use inputValue instead of prefixing with 'Add'
              });
            }

            return filtered;
          }}
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
        />
        {skillType === "primarySkills" && errors.primarySkills && (
          <FormHelperText>{errors.primarySkills}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );

  return (
    <MDBox>
      <MDBox mt={3}>
        <MDTypography variant="h6">Skills</MDTypography>
        <Grid container spacing={3}>
          {renderSkillSelect("primarySkills", "Required")}
        </Grid>

        <Grid container spacing={3}>
          {renderSkillSelect("secondarySkills", "Highly Desired")}
        </Grid>
        <Grid container spacing={3}>
          {renderSkillSelect("tertiarySkills", "Nice to have")}
        </Grid>

        <StepNavigation
          activeStep={activeStep}
          handleLocalNext={handleLocalNext}
          onBack={handleLocalBack}
        />
      </MDBox>
    </MDBox>
  );
}

export default JobRequirements;
