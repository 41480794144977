import { useAuth0 } from '@auth0/auth0-react'
import React, { createContext, useState, ReactNode, useContext } from 'react'
import { JobPost } from 'apis/JobPost'

interface WizardProviderProps {
  children: ReactNode
}

export interface JobPostData {
  company: string
  jobTitle: string
  location: string
  locationRequired: boolean
  clearanceLevel: string
  screeningQuestions: string[]
  primarySkills: { value: string; id: string }[]
  secondarySkills: { value: string; id: string }[]
  tertiarySkills: { value: string; id: string }[]
  educationRequirements: string
  salaryMin: string
  salaryMax: string
  salaryRange: string
  certifications: string[]
  yearsExperience: number
  description: string
  postedBy: string
}

interface WizardContextType {
  jobPostData: JobPostData
  updateJobPostData: (value: JobPostData | ((prevData: JobPostData) => JobPostData)) => void // Add this
  resetJobPostData: () => void
  handleSubmitJobPostData: () => Promise<string>
}

const defaultValues: JobPostData = {
  company: '',
  jobTitle: '',
  location: '',
  locationRequired: false,
  clearanceLevel: '',
  screeningQuestions: [],
  primarySkills: [],
  secondarySkills: [],
  tertiarySkills: [],
  educationRequirements: '',
  salaryMin: '',
  salaryMax: '',
  salaryRange: '',
  certifications: [],
  yearsExperience: 0,
  description: '',
  postedBy: ''
}

export const WizardContext = createContext<WizardContextType>({
  jobPostData: defaultValues,
  updateJobPostData: () => {},
  resetJobPostData: () => {},
  handleSubmitJobPostData: async () => '' // Dummy implementation
})

export const useWizardContext = () => useContext(WizardContext)

export const WizardProvider: React.FC<WizardProviderProps> = ({ children }) => {
  const [jobPostData, setJobPostData] = useState<JobPostData>(defaultValues)

  const { user, getAccessTokenSilently } = useAuth0()

  const updateJobPostData = (value: JobPostData | ((prevData: JobPostData) => JobPostData)) => {
    if (typeof value === 'function') {
      setJobPostData(value)
    } else {
      setJobPostData(value)
    }
  }

  const resetJobPostData = () => {
    setJobPostData(defaultValues)
  }

  const handleSubmitJobPostData = async () => {
    try {
      const token = await getAccessTokenSilently()

      // Destructure the jobPostData to separate out salaryMin, salaryMax
      jobPostData['postedBy'] = user.sub

      let { locationRequired, salaryMin, salaryMax, ...dataToSubmit } = jobPostData

      const response = await JobPost.create(dataToSubmit, false, token)
      return response.id // Return the response data (e.g., Job ID)
    } catch (error) {
      console.error('Error submitting job post:', error)
      throw error
    }
  }

  return (
    <WizardContext.Provider
      value={{
        jobPostData,
        updateJobPostData,
        resetJobPostData,
        handleSubmitJobPostData
      }}
    >
      {children}
    </WizardContext.Provider>
  )
}
