import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

interface StepNavigationProps {
  activeStep: number;
  handleLocalNext: () => void;
  onBack: () => void;
  onSubmit?: () => void;
  isLastStep?: boolean;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  activeStep,
  handleLocalNext,
  onBack,
  isLastStep,
  onSubmit,
}) => {
  return (
    <MDBox mt={3} display="flex" justifyContent="space-between">
      <Grid container>
        <Grid item xs={6} display="flex" justifyContent="flex-start">
          {activeStep > 0 && (
            <MDButton variant="gradient" color="light" onClick={onBack}>
              Back
            </MDButton>
          )}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          {isLastStep ? (
            <MDButton
              // disabled={isSubmitting}
              type="submit"
              variant="gradient"
              color="info"
              onClick={onSubmit}
            >
              Submit
            </MDButton>
          ) : (
            <MDButton variant="gradient" color="dark" onClick={handleLocalNext}>
              Next
            </MDButton>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default StepNavigation;
