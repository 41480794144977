import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useWizardContext } from "context/AddJobWizard";
import StepNavigation from "../components/StepNavigation";

function QualificationsAndExperience({
  onNext,
  onBack,
  activeStep,
}): JSX.Element {
  const { jobPostData } = useWizardContext();

  const [educationOptions, setEducationOptions] = useState([
    "High School",
    "Bachelors",
    "Masters",
    "PhD",
    "Other",
  ]);

  const [localData, setLocalData] = useState({
    educationRequirements: "",
    yearsExperience: 0,
  });

  useEffect(() => {
    setLocalData({
      educationRequirements: jobPostData.educationRequirements || "",
      yearsExperience: jobPostData.yearsExperience || 0,
    });
  }, [jobPostData]);

  const handleEducationChange = (event, newValue) => {
    setLocalData({
      ...localData,
      educationRequirements: newValue,
    });

    if (newValue && !educationOptions.includes(newValue)) {
      setEducationOptions([...educationOptions, newValue]);
    }
  };

  const handleExperienceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalData({
      ...localData,
      yearsExperience: parseInt(event.target.value, 10) || 0,
    });
  };

  const handleLocalBack = useCallback(() => {
    onBack(localData);
  }, [onBack, localData]);

  const handleLocalNext = useCallback(() => {
    onNext(localData);
  }, [onNext, localData]);

  return (
    <MDBox>
      <MDTypography variant="h5">Qualifications and Experience</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              {/* <InputLabel>Education Requirements</InputLabel> */}
              <Autocomplete
                freeSolo
                options={educationOptions}
                value={localData.educationRequirements}
                onChange={handleEducationChange}
                renderInput={(params) => (
                  <TextField {...params} label="Education Requirements" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              label="Years Exp"
              value={localData.yearsExperience}
              onChange={handleExperienceChange}
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
      </MDBox>
      <StepNavigation
        activeStep={activeStep}
        handleLocalNext={handleLocalNext}
        onBack={handleLocalBack}
      />
    </MDBox>
  );
}

export default QualificationsAndExperience;
