import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const Dashboard = {
  getAnalytics: async function (cancel = false, token) {
    const response = await api.request({
      url: `/dashboard-metrics`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAnalytics.name].handleRequestCancellation()
            .signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(Dashboard);
