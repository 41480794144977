import { useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Candidate } from "apis/Candidate";

const useUpdateCandidate = (id) => {
  const [candidate, setCandidateData] = useState(null);
  const [candidateUpdating, setCandidateUpdating] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const updateCandidate = useCallback(
    async (data, cancel = false) => {
      try {
        const token = await getAccessTokenSilently();

        setCandidateUpdating(true);
        const candidate = await Candidate.update(id, data, false, token);
        setCandidateData((prevState) => candidate);
      } catch (error) {
        setCandidateUpdating(false);
        console.error("Error updating candidate", error);
      } finally {
        setCandidateUpdating(false);
      }
    },
    [getAccessTokenSilently, id]
  );

  return { candidateUpdating, candidate, updateCandidate };
};

export default useUpdateCandidate;
