import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Candidate } from "apis/Candidate";

const useFetchResume = (candidateID) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [htmlContent, setHtmlContent] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!candidateID) return;

    let isMounted = true; // flag to check if component is mounted

    const fetchData = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await Candidate.getResume(candidateID, false, token);

        if (response.extension === "html") {
          console.log("data in html", data);
          setHtmlContent(response.value); // Set the HTML content
        }

        if (response.extension === "pdf") {
          console.log("data in pdf", data);
          setPdfFile(response.value); // Set the PDF file
        }

        if (isMounted) {
          setData(response);
          setLoading(false);
        }
        setData(response);
      } catch (error) {
        // @ts-ignore
        setError(error.message);
        console.error("Error fetching scores:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  return { data, loading, error, pdfFile, htmlContent };
};

export default useFetchResume;
