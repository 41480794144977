import { useEffect, useState } from "react";
import { Candidates } from "apis/Candidates";

import { useAuth0 } from "@auth0/auth0-react";

const useFetchCandidatesForTable = () => {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchCandidates = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoading(true);
        const candidates = await Candidates.get(false, token);

        const updatedTableData = {
          columns: [
            { Header: "name", accessor: "name" },
            { Header: "location", accessor: "location" },
            { Header: "current job", accessor: "currentJob" },
          ],
          rows: candidates.map((candidate) => ({
            id: candidate.id,
            name: candidate.name,
            location: candidate.location,
            currentJob: candidate.currentJob,
          })),
        };

        if (isMounted) {
          setTableData((prevState) => ({
            ...prevState,
            ...updatedTableData,
          }));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching candidates:", error);
      }
    };

    fetchCandidates();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loading, tableData };
};

export default useFetchCandidatesForTable;
