import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Typography,
  Chip,
  Box,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Button,
  DialogActions,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Tooltip,
  Icon,
  Stack,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ScoredCandidatesTable from "layouts/candidates/tables/scored-candidates";
import MatchedCandidates from "layouts/candidates/MatchedCandidates";
import colors from "assets/theme/base/colors";
import useFetchJobPost from "hooks/useFetchJobPost";
import useFetchCandidates from "hooks/useFetchCandidates";
import useFetchScores from "hooks/useFetchScores";
import { StatusChip } from "components/StatusChip";
import useUpdateJobPostStatus from "hooks/useUpdateJobPostStatus";
import MDButton from "components/MDButton";
import useUpdateJob from "hooks/useUpdateJob";
import useDeleteJob from "hooks/useDeleteJobPost";
import JobCreationAndCandidateManagement from "components/JobCreationAndCandidateManagement";
import { useNotifications } from "context/Notifications/NotificationsContext";
import useFetchSkills from "hooks/useFetchSkills";

// Assuming jobData is passed as a prop
const JobPost = () => {
  const { jobId } = useParams();

  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const { loading: jobPostLoading, jobData, refetch: refetchJobData } = useFetchJobPost({ jobId });
  const { loadingScores, jobScoreData } = useFetchScores({ jobId });
  const { candidates, loadingCandidates } = useFetchCandidates();
  const { updatedJobStatus, updateStatus } = useUpdateJobPostStatus({ jobId });
  const { loadingSkills, skills, setSkills } = useFetchSkills();
  const { updateJob } = useUpdateJob({ jobId });
  const { deleteJob } = useDeleteJob({ jobId });

  const certificationOptionsMock: string[] = ["Enter Certifications"];

  const [certificationOptions, setCertificationOptions] = useState(
    certificationOptionsMock
  );

  const [educationOptions, setEducationOptions] = useState([
    "High School",
    "Bachelors",
    "Masters",
    "PhD",
    "Other",
  ]);

  const [tableData, setTableData] = useState(null);
  const [matchedCandidates, setMatchedCandidates] = useState([]);
  const [jobStatus, setJobStatus] = useState(null);
  const [isSendToZohoDialougeOpen, setIsSendToZohoDialougeOpen] =
    useState(false);

  const handleSendToZohoDialougOpen = () => setIsSendToZohoDialougeOpen(true);
  const handleSendToZohoDialougClose = () => setIsSendToZohoDialougeOpen(false);
  // Add state for dialog open/close and form data
  const [editFormData, setEditFormData] = useState({
    company: "",
    jobTitle: "",
    description: "",
    location: "",
    minExp: "",
    education: "",
    salaryRange: "",
    requiredSkills: [],
    highlyDesiredSkills: [],
    niceToHaveSkills: [],
    certifications: [],
  });
  
  useEffect(() => {
    if (jobData) {
      console.log(jobData.salaryRange)
      setEditFormData({
        company: jobData.company,
        jobTitle: jobData.jobTitle,
        description: jobData.description,
        location: jobData.location,
        minExp: jobData.yearsExperience,
        education: jobData.educationRequirements,
        salaryRange: jobData.salaryRange,
        requiredSkills: jobData.primarySkills,
        highlyDesiredSkills: jobData.secondarySkills,
        niceToHaveSkills: jobData.tertiarySkills,
        certifications: jobData.certifications,
      });
    }
  }, [jobData]);
  
  // BIg Fucking fucntion
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  
  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const [editConfirmDialogOpen, setEditConfirmDialogOpen] = useState(false);
  const handleEditConfirmDialogOpen = () => {
    setEditConfirmDialogOpen(true);
  };

  const handleEditConfirmDialogClose = () => {
    setEditConfirmDialogOpen(false);
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputSalaryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: "salaryMin" | "salaryMax"
  ) => {
    const updatedValue = event.target.value;

    let [min, max] = editFormData.salaryRange.replaceAll('$', '').split(" - ");

    if (max === undefined) {
      max = "";
    }

    const newSalaryRange =
      field === "salaryMin"
        ? `${updatedValue} - ${max}`
        : `${min} - ${updatedValue}`;

    setEditFormData({ ...editFormData, salaryRange: newSalaryRange });
  };

  const filter = createFilterOptions();
  
  const addNewSkill = (skillValue) => {
    let existingSkill = skills.find((s) => s.value === skillValue);
    if (!existingSkill) {
      const newId =
        skills.length > 0 ? Math.max(...skills.map((s) => s.id)) + 1 : 1;
      existingSkill = { id: newId, value: skillValue };
      setSkills((prevOptions) => [...prevOptions, existingSkill]);
    }
    return existingSkill;
  };
  
  const handleInputSkillsChange = (skillType, newValue, reason) => {
    if (reason === "selectOption" || reason === "createOption") {
      const updatedSkills = newValue.map((skill) => {
        return typeof skill === "string"
          ? addNewSkill(skill)
          : skill.inputValue
          ? addNewSkill(skill.inputValue)
          : skill;
      });
      setEditFormData((prevData) => ({
        ...prevData,
        [skillType]: updatedSkills,
      }));
    } else {
      setEditFormData((prevData) => ({
        ...prevData,
        [skillType]: newValue,
      }));
    }
  };

  const handleInputCertificationChange = (newValue, reason) => {
    if (reason === "selectOption" || reason === "createOption") {
      newValue.map((newCert) => {
        const existingCertification = certificationOptions.some((cert) => cert.toLowerCase() === newCert.toLowerCase());
        return !existingCertification ? setCertificationOptions(prevData => [...prevData, newCert]) : certificationOptions;
      });
      setEditFormData((prevData) => ({
        ...prevData,
        certifications: newValue,
      }));
    } else {
      setEditFormData({ ...editFormData, certifications: newValue });
    }
  };

  const handleInputEducationChange = (newValue) => {
    setEditFormData({ ...editFormData, education: newValue });
  }

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form data to submit:", editFormData);

    let dataToSubmit = {
      description: editFormData.description,
      jobTitle: editFormData.jobTitle,
      company: editFormData.company,
      location: editFormData.location,
      yearsExperience: editFormData.minExp,
      educationRequirements: editFormData.education,
      salaryRange: editFormData.salaryRange,
      primarySkills: editFormData.requiredSkills,
      secondarySkills: editFormData.highlyDesiredSkills,
      tertiarySkills: editFormData.niceToHaveSkills,
      certifications: editFormData.certifications,
    };

    try {
      const response = await updateJob(dataToSubmit);
      console.log("response", response);
      handleEditDialogClose();
      handleEditConfirmDialogClose();

      addNotification({
        id: new Date().getTime(),
        color: "success",
        icon: "check",
        title: `${editFormData.jobTitle} Updated Successfully`,
        content: "Refreshing",
        dateTime: "Just now",
        bgWhite: true,
      });
      setTimeout(() => {
        refetchJobData()
      }, 2000);
    } catch (error) {
      console.error("Error updating job:", error);
    }

    handleEditDialogClose();
    handleEditConfirmDialogClose();
  };
  
  // State for Delete Dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await deleteJob();
      console.log("response", response);
      handleDeleteDialogClose();

      addNotification({
        id: new Date().getTime(),
        color: "success",
        icon: "check",
        title: `${editFormData.jobTitle} Deleted Successfully`,
        content: "Redirecting",
        dateTime: "Just now",
        bgWhite: true,
      });
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (error) {
      console.error("Error Deleting job:", error);
    }

    handleDeleteDialogClose();
  };

  useEffect(() => {
    // This checks if jobData is loaded and has the isPostOpen property
    if (jobData && jobData.hasOwnProperty("isJobPostOpen")) {
      setJobStatus(jobData.isJobPostOpen);
    }
  }, [jobData]); // Dependency array includes jobData to react to its changes

  useEffect(() => {
    const doesCandidateHaveScore = (candidateId) => {
      return (
        Array.isArray(jobScoreData) &&
        jobScoreData.some((score) => score.idcandidate === candidateId)
      );
    };

    if (candidates !== null && jobScoreData !== null) {
      const columns = [
        { Header: "name", accessor: "name" },
        // { Header: "location", accessor: "location" },
        { Header: "score", accessor: "score" },
        { Header: "source", accessor: "source" },
      ];

      let rows = jobScoreData
        .filter((candidate) => doesCandidateHaveScore(candidate.id))
        .map((candidate) => ({
          id: candidate.idCandidate,
          name: candidate.candidateName,
          score: candidate.score,
          source: candidate.source,
        }));

      // Convert score to a number for sorting, treating non-numeric values as -Infinity
      rows = rows.sort((a, b) => {
        let scoreA =
          Number(a.score) || (a.score === "N/A" ? -Infinity : a.score);
        let scoreB =
          Number(b.score) || (b.score === "N/A" ? -Infinity : b.score);
        return scoreB - scoreA; // For descending order
      });

      // After sorting, slice the array to get the top three candidates
      const topCandidates = rows.slice(0, 3);
      setMatchedCandidates(topCandidates);

      const processData = { columns, rows };
      setTableData([processData]); // Set processData in an array to maintain the expected structure
    }
  }, [candidates, jobScoreData]);

  useEffect(() => {
    setJobStatus(updatedJobStatus?.isJobPostOpen);
  }, [updatedJobStatus]);

  if (
    jobPostLoading ||
    loadingCandidates ||
    loadingScores ||
    !tableData ||
    !jobScoreData
  ) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Skeleton variant="text" width="60%" height={40} />
                    <Skeleton variant="text" width="40%" height={30} />

                    <Divider sx={{ my: 2 }} />

                    <Skeleton variant="text" width="40%" height={30} />
                    <Skeleton variant="text" width="100%" height={100} />
                    <Skeleton variant="text" width="100%" height={100} />

                    <Divider />

                    <Skeleton variant="text" width="40%" height={30} />
                    <Skeleton variant="rectangular" width="100%" height={100} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Skeleton variant="text" width="60%" height={40} />
                    <Skeleton variant="rectangular" width="100%" height={200} />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Skeleton variant="text" width="40%" height={30} />
                <Skeleton variant="rectangular" width="100%" height={300} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }

  const handleUpdateStatus = (newStatus) => {
    // Prepare the status data
    const jobStatusData = {
      isJobPostOpen: newStatus,
    };

    updateStatus(jobStatusData, false);
  };

  const handleSendToZoho = (e) => {
    e.preventDefault();
    console.log("BINGO");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: 10 }}>
                <StatusChip
                  currentStatus={jobStatus}
                  onUpdate={handleUpdateStatus}
                />
                <Stack gap={1} direction={'row'}>
                  <Chip label='Edit' onClick={handleEditDialogOpen}/>
                  <Chip label='Delete' onClick={handleDeleteDialogOpen} color="error"/>
                </Stack>
              </div>
              <div></div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    {jobData.company} ({jobData.clearanceLevel})
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    {jobData.jobTitle}
                  </Typography>

                  {/* Job description and other details */}
                  <Divider sx={{ my: 2 }} />

                  <Typography variant="h5" gutterBottom>
                    Job Description
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {jobData.description}
                  </Typography>

                  <Divider />

                  {/* List out screening questions */}
                  <Typography variant="h5">Screening Questions</Typography>
                  <List>
                    {jobData.screeningQuestions?.map((question, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          // disableTypography
                          primary={
                            <Typography variant="body2">{question}</Typography>
                          }
                          // primary={question}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                    {/* About Client Section */}
                    <Typography variant="h4" gutterBottom>
                      Requirements
                    </Typography>
                    <Box sx={{ display: "block", mb: 2 }}>
                      <Typography variant="body2" component="span">
                        <Box component="span" fontWeight="fontWeightBold">
                          Location:{" "}
                        </Box>
                        {jobData.location}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "block", mb: 2 }}>
                      <Typography variant="body2" component="span">
                        <Box component="span" fontWeight="fontWeightBold">
                          Min Exp:{" "}
                        </Box>
                        {jobData.yearsExperience}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "block", mb: 2 }}>
                      <Typography variant="body2" component="span">
                        <Box component="span" fontWeight="fontWeightBold">
                          Education:{" "}
                        </Box>
                        {jobData.educationRequirements}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "block", mb: 2 }}>
                      <Typography variant="body2" component="span">
                        <Box component="span" fontWeight="fontWeightBold">
                          Salary Range:{" "}
                        </Box>
                        {jobData.salaryRange}($)
                      </Typography>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    {/* Skills Section */}
                    <Typography variant="h6" gutterBottom>
                      Required Skills
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        mb: 2,
                      }}
                    >
                      {jobData.primarySkills?.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill.value}
                          sx={{
                            bgcolor: colors.primarySkill.main,
                            color: "white",
                          }}
                          color="primary"
                        />
                      ))}
                    </Box>

                    <Typography variant="h6" gutterBottom>
                      Highly Desired Skills
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        mb: 2,
                      }}
                    >
                      {jobData.secondarySkills?.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill.value}
                          sx={{
                            bgcolor: colors.secondarySkill.main,
                            color: "white",
                          }}
                          color="primary"
                        />
                      ))}
                    </Box>

                    <Typography variant="h6" gutterBottom>
                      Nice to have Skills
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        mb: 2,
                      }}
                    >
                      {jobData.tertiarySkills?.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill.value}
                          sx={{
                            bgcolor: colors.tertiarySkill.main,
                          }}
                          color="primary"
                        />
                      ))}
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    {/* Certifications Section */}
                    <Typography variant="h6" gutterBottom>
                      Certifications
                    </Typography>
                    <List sx={{ mb: 2 }}>
                      {jobData.certifications?.map((cert, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            // disableTypography
                            primary={
                              <Typography variant="body2">{cert}</Typography>
                            }
                            // primary={question}
                          />
                          {/* <ListItemText primary={cert} /> */}
                        </ListItem>
                      ))}
                    </List>

                    {/* <Divider sx={{ my: 2 }} /> */}

                    {/* Action Buttons Section
                  <Box sx={{ mt: 3 }}>
                    <Button variant="contained" color="primary" fullWidth>
                      Apply Job
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      Save Job
                    </Button>
                  </Box> */}
                  </Box>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Scored Candidates
              </MDTypography>
              <ScoredCandidatesTable tableData={tableData[0]} />
            </MDBox>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={isSendToZohoDialougeOpen}
        onClose={handleSendToZohoDialougClose}
      >
        <form onSubmit={handleSendToZoho}>
          <DialogTitle>Send to Zoho</DialogTitle>

          <JobCreationAndCandidateManagement
            candidates={candidates}
            jobData={jobData}
          />
          <DialogActions>
            <MDButton onClick={handleSendToZohoDialougClose}>Cancel</MDButton>
            <MDButton type="submit">Send to Zoho</MDButton>
          </DialogActions>
        </form>
      </Dialog>

      {/* <MatchedCandidates candidates={matchedCandidates} /> */}
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Job Post</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="company"
              label="Company Name"
              type="text"
              fullWidth
              variant="outlined"
              name="company"
              value={editFormData.company}
              onChange={handleEditFormChange}
            />
            <TextField
              margin="dense"
              id="jobTitle"
              label="Job Title"
              type="text"
              fullWidth
              variant="outlined"
              name="jobTitle"
              value={editFormData.jobTitle}
              onChange={handleEditFormChange}
            />
            <TextField
              margin="dense"
              id="description"
              label="Job Description"
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="description"
              value={editFormData.description}
              onChange={handleEditFormChange}
            />
            <Typography variant="h6" gutterBottom>
              Requirements
            </Typography>
            <TextField
              margin="dense"
              id="location"
              label="Location"
              type="text"
              fullWidth
              variant="outlined"
              name="location"
              value={editFormData.location}
              onChange={handleEditFormChange}
            />
            <TextField
              margin="dense"
              id="minExp"
              label="Min Exp"
              type="number"
              fullWidth
              variant="outlined"
              name="minExp"
              value={editFormData.minExp}
              onChange={handleEditFormChange}
            />
            <Autocomplete
              freeSolo
              options={educationOptions}
              value={editFormData.education}
              onChange={(event: any, newValue: string | null) => {
                handleInputEducationChange(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} margin="dense" label="Education" id="education" name="education" />
              )}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  type="number"
                  label="Minimum Salary"
                  value={editFormData.salaryRange.split(" - ")[0].replace("$", "")}
                  onChange={(e) => handleInputSalaryChange(e, "salaryMin")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  type="number"
                  label="Maximum Salary"
                  value={editFormData.salaryRange.split(" - ")[1].replace("$", "")}
                  onChange={(e) => handleInputSalaryChange(e, "salaryMax")}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom>
              Required Skills
            </Typography>
            <Autocomplete
              disabled={loadingSkills}
              multiple
              id={`combo-box-required-skills`}
              options={skills}
              renderInput={(params) => (
                <TextField {...params} />
              )}
              value={editFormData.requiredSkills}
              getOptionLabel={(option) => option.value}
              onChange={(event, newValue, reason) =>
                handleInputSkillsChange("requiredSkills", newValue, reason)
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
    
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
    
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: `Add ${inputValue}`,
                  });
                }
    
                return filtered;
              }}
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
            />
            <Typography variant="h6" gutterBottom>
              Highly Desired Skills
            </Typography>
            <Autocomplete
              disabled={loadingSkills}
              multiple
              id={`combo-box-desired-skills`}
              options={skills}
              renderInput={(params) => (
                <TextField {...params} />
              )}
              value={editFormData.highlyDesiredSkills}
              getOptionLabel={(option) => option.value}
              onChange={(event, newValue, reason) =>
                handleInputSkillsChange("highlyDesiredSkills", newValue, reason)
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
    
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
    
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: `Add ${inputValue}`,
                  });
                }
    
                return filtered;
              }}
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
            />
            <Typography variant="h6" gutterBottom>
              Nice to Have Skills
            </Typography>
            <Autocomplete
              disabled={loadingSkills}
              multiple
              id={`combo-box-nice-skills`}
              options={skills}
              renderInput={(params) => (
                <TextField {...params} />
              )}
              value={editFormData.niceToHaveSkills}
              getOptionLabel={(option) => option.value}
              onChange={(event, newValue, reason) =>
                handleInputSkillsChange("niceToHaveSkills", newValue, reason)
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
    
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
    
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: `Add ${inputValue}`,
                  });
                }
    
                return filtered;
              }}
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
            />
            <Typography variant="h6" gutterBottom>
              Certifications
            </Typography>
            <Autocomplete
              multiple
              id={`combo-box-certification`}
              options={certificationOptionsMock}
              value={editFormData.certifications}
              getOptionLabel={(option) => {
                const isExisting = certificationOptions.includes(option);
                if (option && !isExisting) {
                  return `Add ${option.trim()}`;
                }
                return option;
              }}
              renderInput={(params) => (
                <TextField {...params} label={"Certifications"} />
              )}
              onChange={(event, newValue, reason) =>
                handleInputCertificationChange(newValue, reason)
              }
              filterOptions={(options, params) => {
                const filtered: string[] = options.filter((option) =>
                  option
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())
                );
                const { inputValue } = params;

                const isExisting = options.some(
                  (option) =>
                    inputValue.toLowerCase() === option.toLowerCase()
                );

                if (inputValue.trim() !== "" && !isExisting) {
                  filtered.push(inputValue.trim());
                }

                return filtered;
              }}
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose}>Cancel</Button>
            <Button onClick={handleEditConfirmDialogOpen}>Update</Button>
          </DialogActions>
      </Dialog>

      <Dialog open={editConfirmDialogOpen} onClose={handleEditConfirmDialogClose}>
        <DialogTitle>Update Job Post</DialogTitle>
        <form onSubmit={handleEditFormSubmit}>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to edit this post, it will re-score all the candidates
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditConfirmDialogClose}>Cancel</Button>
            <Button type="submit">Update</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Delete Job Post</DialogTitle>
        <form onSubmit={handleDeleteFormSubmit}>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete this job post?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>Cancel</Button>
            <Button type="submit">Delete</Button>
          </DialogActions>
        </form>
      </Dialog>
    </DashboardLayout>
  );
};

export default JobPost;
