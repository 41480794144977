import { useCallback, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { User } from "apis/User";

const useUpdateUserProfile = ({ userId }) => {
  const [user, setUser] = useState(null);
  const [userUpdating, setUserUpdating] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const updateUser = useCallback(
    async (data, cancel = false) => {
      try {
        const token = await getAccessTokenSilently();

        setUserUpdating(true);
        const response = await User.updateUser(userId, data, false, token);

        setUser((prevState) => response);

        return response;
      } catch (error) {

        setUserUpdating(false);
        console.error("Error updating user", error);
      } finally {

        setUserUpdating(false);
      }
    },
    [getAccessTokenSilently, userId]
  );

  return { userUpdating, user, updateUser };
};

export default useUpdateUserProfile;
