import { useState, useCallback } from "react";
import { JobPost } from "apis/JobPost";
import { useAuth0 } from "@auth0/auth0-react";

const useUpdateJob = ({ jobId }) => {
  const [isJobPostUpdateInProgress, setIsJobPostUpdateInProgress] =
    useState(false);
  const [error, setError] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const updateJob = useCallback(
    async (jobData, cancel = false) => {
      setIsJobPostUpdateInProgress(true);
      setError(null);

      try {
        const token = await getAccessTokenSilently();
        const response = await JobPost.update(jobId, jobData, cancel, token);

        return response;
      } catch (error) {
        console.error("Error updating job:", error);
        setError(error);
      } finally {
        setIsJobPostUpdateInProgress(false);
      }
    },
    [getAccessTokenSilently, jobId]
  );

  return { isJobPostUpdateInProgress, updateJob, error };
};

export default useUpdateJob;
