// BasicJobInfo.tsx
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { FormHelperText, TextField } from "@mui/material";
import { useWizardContext } from "context/AddJobWizard";
import StepNavigation from "../components/StepNavigation";

function BasicJobInfo({ onNext, onBack, activeStep }): JSX.Element {
  const [isLocationRequired, setLocationRequired] = useState(false);
  const { jobPostData } = useWizardContext();

  const [localData, setLocalData] = useState({
    company: "",
    jobTitle: "",
    location: "",
    description: "",
  });

  // State for error messages
  const [errors, setErrors] = useState({
    company: "",
    jobTitle: "",
    description: "",
  });

  // Validation function
  const validate = () => {
    let tempErrors = { company: "", jobTitle: "", description: "" };
    tempErrors.company = localData.company ? "" : "This field is required";
    tempErrors.jobTitle = localData.jobTitle ? "" : "This field is required";
    tempErrors.description = localData.description
      ? ""
      : "This field is required";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  // Initialize localData with jobPostData when the component mounts
  useEffect(() => {
    setLocalData({
      company: jobPostData.company,
      jobTitle: jobPostData.jobTitle,
      location: jobPostData.location,
      description: jobPostData.description,
    });
    setLocationRequired(jobPostData.locationRequired);
  }, [jobPostData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLocationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationRequired(event.target.checked);
    setLocalData((prevData) => ({
      ...prevData,
      locationRequired: event.target.checked,
    }));
  };

  const handleLocalNext = () => {
    if (validate()) {
      // Update global state with local data
      onNext(localData);
    }
  };

  console.log("LOCAL DATA", localData);
  return (
    <MDBox>
      <MDTypography variant="h5">Basic Job Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDInput
              type="text"
              label="Company"
              name="company"
              value={localData.company}
              onChange={handleInputChange}
              error={!!errors.company}
              helperText={errors.company}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              type="text"
              label="Job Title (Formal)"
              name="jobTitle"
              value={localData.jobTitle}
              onChange={handleInputChange}
              error={!!errors.jobTitle}
              helperText={errors.jobTitle}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={6} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLocationRequired}
                  onChange={handleLocationToggle}
                  color="primary"
                />
              }
              label="Required"
            />
            <MDInput
              type="text"
              label="Location"
              name="location"
              onChange={handleInputChange}
              fullWidth
              disabled={!isLocationRequired}
              value={localData.location}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <TextField
          label="Description"
          multiline
          rows={4}
          name="description"
          value={localData.description}
          onChange={(e) =>
            setLocalData({ ...localData, description: e.target.value })
          }
          error={!!errors.description}
          helperText={errors.description}
          fullWidth
          required
        />
      </MDBox>
      <StepNavigation
        activeStep={activeStep}
        handleLocalNext={handleLocalNext}
        onBack={onBack}
      />
    </MDBox>
  );
}

export default React.memo(BasicJobInfo);
