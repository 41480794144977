import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const Scores = {
  get: async function (jobId, cancel = false, token) {
    const response = await api.request({
      url: `/score/${jobId}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
  getCandidateAndJob: async function (
    jobId,
    candidateId,
    cancel = false,
    token
  ) {
    const response = await api.request({
      url: `/score/${jobId}/${candidateId}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(Scores);
