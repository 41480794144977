// NotificationContext.tsx

import React, { createContext, useContext, useState, ReactNode } from "react";

export interface Notification {
  id: string | number;
  color?:
    | "light"
    | "success"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "warning"
    | "dark";
  icon?: string;
  title?: string;
  content: string;
  dateTime?: string;
  bgWhite?: boolean;
}

interface NotificationContextType {
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  removeNotification: (id: string | number) => void;
}

const defaultState: NotificationContextType = {
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
};

const NotificationContext =
  createContext<NotificationContextType>(defaultState);

export const useNotifications = () => useContext(NotificationContext);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (notification: Notification) => {
    setNotifications((prev) => [...prev, notification]);
  };

  const removeNotification = (id: string | number) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
