import { useState, useCallback } from "react";
import { JobPost } from "apis/JobPost";
import { useAuth0 } from "@auth0/auth0-react";

const useUpdateJobPostStatus = ({ jobId }) => {
  const [isJobPostStatusLoading, setIsJobPostStatusLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updatedJobStatus, setJobStatus] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const updateStatus = useCallback(
    async (jobStatusInput, cancel = false) => {
      setIsJobPostStatusLoading(true);
      setError(null);

      try {
        const token = await getAccessTokenSilently();
        const data = await JobPost.updateJobStatus(
          jobId,
          jobStatusInput,
          cancel,
          token
        );

        setJobStatus(data);
      } catch (error) {
        console.error("Error updating job status:", error);
        setError(error);
      } finally {
        setIsJobPostStatusLoading(false);
      }
    },
    [getAccessTokenSilently, jobId]
  );

  return { isJobPostStatusLoading, updatedJobStatus, error, updateStatus };
};

export default useUpdateJobPostStatus;
