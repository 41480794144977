import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { User } from "apis/User";

const useFetchUserProfile = ({ userId }) => {
  const [user, setUser] = useState(undefined);
  const [loadingUser, setLoadingUser] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const fetchUserProfile = async () => {
    try {
      const token = await getAccessTokenSilently();

      setLoadingUser(true);
      const response = await User.getUser(userId, false, token);

      setUser(response);
      setLoadingUser(false);
    } catch (error) {
      setLoadingUser(false);
      console.error("Error fetching user:", error);
    }
  };
  useEffect(() => {
    fetchUserProfile();
  }, []);

  return { loadingUser, user, fetchUserProfile };
};

export default useFetchUserProfile;
