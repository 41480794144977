import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Dashboard } from "apis/Dashboard";

const useFetchDashboardAnalytics = () => {
  const [dashboardAnalytics, setDashboardAnalytics] = useState(null);
  const [loadingDashboardAnalytics, setLoadingDashboardAnalytics] =
    useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchDashboardAnalytics = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLoadingDashboardAnalytics(true);
        const analytics = await Dashboard.getAnalytics(false, token);

        if (isMounted) {
          setDashboardAnalytics((prevState) => analytics);
          setLoadingDashboardAnalytics(false);
        }
      } catch (error) {
        setLoadingDashboardAnalytics(false);
        console.error("Error fetching analytics", error);
      }
    };

    fetchDashboardAnalytics();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { loadingDashboardAnalytics, dashboardAnalytics };
};

export default useFetchDashboardAnalytics;
