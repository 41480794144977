// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";

// Data
import channelChartData from "layouts/dashboards/sales/components/ChannelsChart/data";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";

function ChannelsChart(data): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [chartData, setData] = useState(null);

  function createChannelChartData(inputData) {
    // Define an array of background colors for the chart.
    // Extend or modify this array based on your color scheme.
    const backgroundColors = [
      "info",
      "primary",
      "dark",
      "secondary",
      "warning",
      "danger",
    ];

    // Extract labels and data from the input.
    const labels = inputData.data.map((item) => item.source);
    const data = inputData.data.map((item) => item.value);

    // If you have more data points than colors, you might want to cycle through colors or generate them.
    // This simple example just repeats the color array to ensure enough colors.
    // Adjust this logic based on your specific needs.
    const repeatedBackgroundColors = [];
    while (repeatedBackgroundColors.length < labels.length) {
      repeatedBackgroundColors.push(...backgroundColors);
    }
    const chartBackgroundColors = repeatedBackgroundColors.slice(
      0,
      labels.length
    );

    // Construct the channelChartData structure.
    const channelChartData = {
      labels: labels,
      datasets: {
        label: "Projects",
        backgroundColors: chartBackgroundColors,
        data: data,
      },
    };

    console.log("channelChartData");
    console.log(channelChartData);

    return channelChartData;
  }

  useEffect(() => {
    console.log("data");
    console.log(data);
    const response = createChannelChartData(data);
    setData(response);
  }, [data]);

  if (!chartData) {
    return;
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <MDTypography variant="h6">Channels</MDTypography>
        <Tooltip title="Traffic Channels Used" placement="bottom" arrow>
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            circular
            iconOnly
          >
            <Icon>priority_high</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
      <MDBox mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <PieChart chart={chartData} height="12.5rem" />
          </Grid>
          <Grid item xs={5}>
            <MDBox pr={1}>
              <MDBox mb={1}>
                <MDBadgeDot color="info" size="sm" badgeContent="Indeed" />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot color="primary" size="sm" badgeContent="LinkedIN" />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {/* <MDBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            More than <strong>1,200,000</strong> sales are made using referral
            marketing, and <strong>700,000</strong> are from social media.
          </MDTypography>
        </MDBox>
        <MDBox
          width={{ xs: "100%", sm: "40%" }}
          textAlign="right"
          mt={{ xs: 2, sm: "auto" }}
        >
          <MDButton color={darkMode ? "white" : "light"}>read more</MDButton>
        </MDBox>
      </MDBox> */}
    </Card>
  );
}

export default ChannelsChart;
