import MDBox from "components/MDBox";

import DataTable from "examples/Tables/DataTable/Candidates/scored-candidate-table";

// Data

function DataTables({ tableData }): JSX.Element {
  return (
    <>
      <MDBox pt={6} pb={3}>
        <DataTable isSorted={true} table={tableData} />
      </MDBox>
    </>
  );
}

export default DataTables;
