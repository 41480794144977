import useFetchCandidates from "hooks/useFetchCandidates";
import { getAccessTokenFromCookie } from "pages/ZohoInbound";
import React, { useState, useEffect } from "react";

function splitName(fullName) {
  // Split the full name into an array based on spaces
  const parts = fullName.split(" ");

  // The first part is the first name
  const firstName = parts[0];

  // The last part is the last name
  // This approach assumes the last name is the last element of the array
  // This might not work correctly for names with middle names or multiple last names without modification
  const lastName = parts[parts.length - 1];

  return { firstName, lastName };
}

const JobCreationAndCandidateManagement = ({ jobData, candidates }) => {
  const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working

  useEffect(() => {
    const handleProcess = async () => {
      // TODO #1: jobData and transform to proper object

      const dataSubmission = {
        Posting_Title: jobData.jobTitle, // Assuming jobData is the object containing your job information
        Job_Description: jobData.description,
        Job_Opening_Name: jobData.jobTitle,
        Client_Name: jobData.company,
        Required_Skills: jobData.primarySkills
          .map((skill) => skill.value)
          .join(", "),
        Location: jobData.location,
        Salary: jobData.salaryMax,
      };

      console.log("dataSubmission in handleProcess");
      console.log(dataSubmission);
      try {
        // Step 1: Create a job post in Zoho Recruit API
        const jobResponse = await fetch("http://localhost:3001/api/submitJob", {
          method: "POST",
          headers: {
            Authorization: `Zoho-oauthtoken ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSubmission),
        });
        const job = await jobResponse.json();

        console.log("job");
        console.log(job);
        console.log("candidates");
        console.log(candidates);

        // TODO: Correct Data Structure
        const jobId = job.data[0].details.id;

        // Step 3: Create candidates in Zoho from list of candidates
        const candidateIds = await Promise.all(
          candidates.map(async (candidate) => {
            const { firstName, lastName } = splitName(candidate.name);

            const candidateData = {
              // This is dummy data; replace it with the actual data structure expected by Zoho Recruit
              Current_Job_Title: candidate.currentJob,
              Email: candidate.email,
              Country: candidate.location,
              Full_Name: candidate.name,
              Phone: candidate.phone,
              Source: candidate.source,
              Skill_set: candidate.skills
                .map((skill) => skill.value)
                .join(", "),
              First_Name: firstName,
              Last_Name: lastName,
            };

            const response = await fetch(
              "http://localhost:3001/api/submitCandidate",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Zoho-oauthtoken ${accessToken}`,
                },
                body: JSON.stringify(candidateData),
              }
            );

            console.log(response);
            const data = await response.json();

            // TODO: check data strucuter
            return data.id; // Assuming the response includes the candidate ID
          })
        );

        // Step 5: Call Zoho and associate the IDs of candidates to the ID of the job
        await associateCandidatesToJob(jobId, candidateIds);
      } catch (error) {
        console.error("Error in process:", error);
      }
    };

    handleProcess();
  }, []); // Dependency array is empty to run only once after initial render

  // Function to associate candidates to job, to be defined...
  const associateCandidatesToJob = async (jobId, candidateIds) => {
    // Implementation depends on Zoho API for associating candidates with a job post
    console.log("jobId");
    console.log(jobId);

    console.log("candidateIds");
    console.log(candidateIds);
  };

  return <div>{/* UI components here */}</div>;
};

export default JobCreationAndCandidateManagement;
