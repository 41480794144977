import { useCallback, useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import BasicJobInfo from "./steps/BasicJobInfo";
import ClearanceAndScreening from "./steps/ClearanceAndScreening";
import JobRequirements from "./steps/Requirements";
import QualificationsAndExperience from "./steps/QualificationsAndExperience";
import CompensationAndCertifications from "./steps/CompensationAndCertifications";

import ReviewAndSubmit from "./steps/Review";
import { JobPostData, useWizardContext } from "../../../context/AddJobWizard";

function NewJobPost(): JSX.Element {
  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = [
    "1. Job Description",
    "2. Clearance & Screening",
    "3. Requirements",
    "4. Qualifications & Experience",
    "5. Compensation & Certifications",
    "6. Review",
  ];
  const isLastStep: boolean = activeStep === steps.length - 1;
  const { updateJobPostData, jobPostData } = useWizardContext();

  const handleNext = useCallback(
    (stepData: Partial<JobPostData>) => {
      updateJobPostData((prevData: JobPostData) => ({
        ...prevData,
        ...stepData,
      }));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
    [updateJobPostData]
  );

  const handleBack = useCallback(
    (stepData: Partial<JobPostData>) => {
      updateJobPostData((prevData: JobPostData) => ({
        ...prevData,
        ...stepData,
      }));
      setActiveStep(activeStep - 1);
    },
    [updateJobPostData, activeStep]
  );

  const stepComponents = useMemo(
    () => {
      const createStepComponent = (Component, extraProps = {}) => (
        <Component
          onNext={handleNext}
          onBack={handleBack}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          {...extraProps}
        />
      );
      return [
        createStepComponent(BasicJobInfo),
        createStepComponent(ClearanceAndScreening),
        createStepComponent(JobRequirements),
        createStepComponent(QualificationsAndExperience),
        createStepComponent(CompensationAndCertifications),
        createStepComponent(ReviewAndSubmit, { isLastStep }),
      ];
    },
    [activeStep, handleBack, handleNext, isLastStep] // Include all dependencies used in createStepComponent
  );

  console.log("jobPostData", jobPostData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <MDBox mt={6} mb={8} textAlign="center"></MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>{stepComponents[activeStep]}</MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewJobPost;
