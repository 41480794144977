import { useState, useEffect, useCallback, ChangeEvent } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useWizardContext } from "context/AddJobWizard";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import StepNavigation from "../components/StepNavigation";

function CompensationAndCertifications({
  onNext,
  onBack,
  activeStep,
}): JSX.Element {
  const { jobPostData } = useWizardContext();
  const certificationOptionsMock: string[] = ["Enter Certifications"];

  const [certificationOptions, setCertificationOptions] = useState(
    certificationOptionsMock
  );

  const [localData, setLocalData] = useState({
    salaryRange: "0 - 0", // Assuming a default value
    certifications: [""], // Assuming an empty string as default
  });

  useEffect(() => {
    setLocalData({
      salaryRange:
        jobPostData.salaryRange === "" ? "0 - 0" : jobPostData.salaryRange,
      certifications:
        jobPostData.certifications && jobPostData.certifications.length > 0
          ? jobPostData.certifications.map((cert) => cert) // Transform to an array of strings
          : [],
    });
  }, [jobPostData]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: "salaryMin" | "salaryMax"
  ) => {
    const updatedValue = event.target.value;

    // Split the existing salaryRange into min and max values.
    let [min, max] = localData.salaryRange.split(" - ");

    if (max === undefined) {
      max = "";
    }

    // Create the new salaryRange string based on the field that was changed.
    const newSalaryRange =
      field === "salaryMin"
        ? `${updatedValue} - ${max}`
        : `${min} - ${updatedValue}`;

    // Update the localData state with the new salaryRange.
    setLocalData({ ...localData, salaryRange: newSalaryRange });
  };

  const handleCertificationChange = (newValue, reason) => {
    if (reason === "selectOption" || reason === "createOption") {
      newValue.map((newCert) => {
        const existingCertification = certificationOptions.some((cert) => cert.toLowerCase() === newCert.toLowerCase());
        return !existingCertification ? setCertificationOptions(prevData => [...prevData, newCert]) : certificationOptions;
      });
      setLocalData((prevData) => ({
        ...prevData,
        certifications: newValue,
      }));
    } else {
      setLocalData({ ...localData, certifications: newValue });
    }
  };

  const handleLocalBack = useCallback(() => {
    onBack(localData);
  }, [onBack, localData]);

  const handleLocalNext = useCallback(() => {
    onNext(localData);
  }, [onNext, localData]);

  return (
    <MDBox>
      <MDTypography variant="h5">Compensation and Certifications</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              label="Minimum Salary"
              value={localData.salaryRange.split(" - ")[0]}
              onChange={(e) => handleInputChange(e, "salaryMin")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              label="Maximum Salary"
              value={localData.salaryRange.split(" - ")[1]}
              onChange={(e) => handleInputChange(e, "salaryMax")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id={`combo-box-certification`}
                options={certificationOptions}
                value={localData.certifications}
                getOptionLabel={(option) => {
                  const isExisting = certificationOptions.includes(option);
                  if (option && !isExisting) {
                    return `Add ${option.trim()}`;
                  }
                  return option;
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"Certifications"} />
                )}
                onChange={(event, newValue, reason) =>
                  handleCertificationChange(newValue, reason)
                }
                filterOptions={(options, params) => {
                  const filtered: string[] = options.filter((option) =>
                    option
                      .toLowerCase()
                      .includes(params.inputValue.toLowerCase())
                  );
                  const { inputValue } = params;

                  const isExisting = options.some(
                    (option) =>
                      inputValue.toLowerCase() === option.toLowerCase()
                  );

                  if (inputValue.trim() !== "" && !isExisting) {
                    filtered.push(inputValue.trim());
                  }

                  return filtered;
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
              />
            </FormControl>
          </Grid>
        </Grid>
        <StepNavigation
          activeStep={activeStep}
          handleLocalNext={handleLocalNext}
          onBack={handleLocalBack}
        />
      </MDBox>
    </MDBox>
  );
}

export default CompensationAndCertifications;
