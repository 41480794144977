import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const Candidate = {
  get: async function (id, cancel = false, token) {
    const response = await api.request({
      url: `/candidate/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data[0];
  },
  getResume: async function (id, cancel = false, token) {
    const response = await api.request({
      url: `/candidate/${id}/download-resume`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
  update: async function (id, data, cancel = false, token) {
    const response = await api.request({
      url: `/candidate/${id}`,
      method: "PUT",
      data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(Candidate);
