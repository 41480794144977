

// Countries flags
import US from "assets/images/icons/flags/US.png";
import DE from "assets/images/icons/flags/DE.png";
import GB from "assets/images/icons/flags/GB.png";
import BR from "assets/images/icons/flags/BR.png";

const salesTableData = [
  {
    office: [US, "united state"],
    "open jobs": 2500,
    value: "$230,900",
    "Closed this month": "29.9%",
  },
  {
    office: [DE, "germany"],
    sales: 123,
    value: "$440,000",
    "Closed this month": "40.22%",
  },
  {
    office: [GB, "great britain"],
    sales: 12,
    value: "$190,700",
    "Closed this month": "23.44%",
  },
  { office: [BR, "brasil"], sales: 562, value: "$143,960", "Closed this month": "32.14%" },
];

export default salesTableData;
