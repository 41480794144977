import { useState, useCallback } from "react";
import { JobPost } from "apis/JobPost";
import { useAuth0 } from "@auth0/auth0-react";

const useDeleteJob = ({ jobId }) => {
  const [isJobPostDeleteInProgress, setIsJobPostDeleteInProgress] =
    useState(false);
  const [error, setError] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const deleteJob = useCallback(
    async (cancel = false) => {
      setIsJobPostDeleteInProgress(true);
      setError(null);

      try {
        const token = await getAccessTokenSilently();
        const response = await JobPost.delete(jobId, cancel, token);

        return response;
      } catch (error) {
        console.error("Error deleting job:", error);
        setError(error);
      } finally {
        setIsJobPostDeleteInProgress(false);
      }
    },
    [getAccessTokenSilently, jobId]
  );

  return { isJobPostDeleteInProgress, deleteJob, error };
};

export default useDeleteJob;
