import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";

import SignInBasic from "layouts/authentication/sign-in/basic";

// Pages
import { JobPostPage, AllCandidatesPage, AllJobsPage } from "pages";
import { ZohoAuthCallback } from "pages/ZohoInbound";
import ZohoAuth from "pages/ZohoAuth";
import SubmitJob from "pages/ZohoJobSubmission";

import NewJobPost from "layouts/job-posts/add-new-job";
import CandidateProfileFull from "layouts/candidates/CandidateProfileFull";
import CandidateJobComparison from "layouts/candidates/CandidateComparison";
import Sales from "layouts/dashboards/sales";
import LinkedInSearchForm from "layouts/search";

const routes = [
  {
    path: "/login",
    element: <SignInBasic />,
  },
  {
    path: "/pages/profile/profile-overview",
    element: <ProfileOverview />,
  },
  {
    path: "/pages/account/settings",
    element: <Settings />,
  },
  {
    path: "/dashboard",
    element: <Sales />,
  },
  {
    path: "/job-postings",
    element: <AllJobsPage />,
  },
  {
    path: "/jobs/add-new",
    element: <NewJobPost />,
  },
  {
    path: "/jobs/:jobId",
    element: <JobPostPage />,
  },
  {
    path: "/candidates",
    element: <AllCandidatesPage />,
  },
  {
    path: "/candidate/:id",
    element: <CandidateProfileFull />,
  },
  {
    path: "/candidate/:candidateId/:jobId",
    element: <CandidateJobComparison />,
  },
  {
    path: "/search/linkedin",
    element: <LinkedInSearchForm />,
  },
  {
    path: "/zoho",
    element: <ZohoAuth />,
  },
  {
    path: "/zoho-inbound",
    element: <ZohoAuthCallback />,
  },
  {
    path: "/submit-job",
    element: <SubmitJob />,
  },
];

export { routes };
