import { JobPosts } from "apis/JobPosts";
import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

const tableColumns = [
  { Header: "Job Title", accessor: "jobTitle" },
  { Header: "Company", accessor: "company" },
  { Header: "Status", accessor: "status" },
];

const useFetchJobPostsForDashboard = () => {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [latestJobPostsLoading, setLatestJobPostsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true; // flag to check if component is mounted

    const fetchJobPosts = async () => {
      try {
        const token = await getAccessTokenSilently();

        setLatestJobPostsLoading(true);
        const jobPosts = await JobPosts.getNumberOfJobs(false, token);
        const rows = jobPosts.map((job) => ({
          id: job.id,
          jobTitle: job.jobTitle,
          company: job.company,
          status: job.isJobPostOpen ? "Open" : "Closed",
        }));

        if (isMounted) {
          setTableData((prevState) => ({
            ...prevState,
            columns: tableColumns,
            rows,
          }));
          setLatestJobPostsLoading(false);
        }
      } catch (error) {
        setLatestJobPostsLoading(false);
        console.error("Error fetching job posts:", error);
      }
    };

    fetchJobPosts();

    // Cleanup function to set the mounted flag to false
    return () => {
      isMounted = false;
    };
  }, []); // Add dependencies here if needed

  return { latestJobPostsLoading, tableData };
};

export default useFetchJobPostsForDashboard;
