import MDButton from "components/MDButton";
import { getAccessTokenFromCookie } from "./ZohoInbound";
import { Box } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Job ID
// "293027000003596284"

// Candidate ID
// "293027000003599253"

const SubmitJob = () => {
  const submitEditJob = async () => {
    const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working
    const jobData = {
      // This is dummy data; replace it with the actual data structure expected by Zoho Recruit
      Posting_Title: "TEST-EDIT Posting_Title",
      Job_Description: "TEST-EDIT Job_Description",
      Job_Opening_Name: "TEST-EDIT Job_Opening_Name",
      Client_Name: "TEST-EDIT Client_Name",
      Salary: "EDIT - 120,000",
      Required_Skills: "React, Test-EDIT1",
      Location: "TEST-EDIT Location", // NOT WORKING
    };

    console.log("EDIT-Job Data");
    console.log(jobData);

    const response = await fetch(
      `http://localhost:3001/api/submitJob/293027000003598007`,
      {
        method: "PUT",
        headers: {
          Authorization: `Zoho-oauthtoken ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jobData),
      }
    );

    if (response.ok) {
      const data = await response.json();
      console.log("Job submitted successfully:", data);
      // Handle success, maybe redirect or show a success message
    } else {
      console.error("Failed to submit job");
      // Handle error
    }
  };

  const associateCandidate = async () => {
    const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working
    const associateData = {
      // This is dummy data; replace it with the actual data structure expected by Zoho Recruit
      jobids: ["293027000003596284"],
      ids: ["293027000003599253"],
      comments: "associate",
    };

    console.log("associateData");
    console.log(associateData);

    const response = await fetch("http://localhost:3001/api/associateData", {
      method: "PUT",
      headers: {
        Authorization: `Zoho-oauthtoken ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(associateData),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Candidate successfully:", data);
      // Handle success, maybe redirect or show a success message
    } else {
      console.error("Failed to submit candidate");
      // Handle error
    }
  };
  const submitCandidate = async () => {
    const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working
    const candidateData = {
      // This is dummy data; replace it with the actual data structure expected by Zoho Recruit
      Current_Job_Title: "TEST Candidate Job Title",
      Email: "test@test232.com",
      Country: "TEST Country",
      Full_Name: "TEST Client Name",
      Phone: "07566746531",
      Source: "linkedin",
      Skill_set: "react, aws",
      First_Name: "First Name",
      Last_Name: "Last Name",
      Score: "85",
    };

    console.log("candidateData");
    console.log(candidateData);

    const response = await fetch("http://localhost:3001/api/submitCandidate", {
      method: "POST",
      headers: {
        Authorization: `Zoho-oauthtoken ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(candidateData),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Candidate successfully:", data);
      // Handle success, maybe redirect or show a success message
    } else {
      console.error("Failed to submit candidate");
      // Handle error
    }
  };

  const submitJob = async () => {
    const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working
    const jobData = {
      // This is dummy data; replace it with the actual data structure expected by Zoho Recruit
      Posting_Title: "TEST Posting_Title",
      Job_Description: "TEST Job_Description",
      Job_Opening_Name: "TEST Job_Opening_Name",
      Client_Name: "TEST Client_Name",
      Salary: "120,000",
      Required_Skills: "React, Test1",
      Location: "TEST Location",
    };

    console.log("jobData");
    console.log(jobData);

    const response = await fetch("http://localhost:3001/api/submitJob", {
      method: "POST",
      headers: {
        Authorization: `Zoho-oauthtoken ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jobData),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Job submitted successfully:", data);
      // Handle success, maybe redirect or show a success message
    } else {
      console.error("Failed to submit job");
      // Handle error
    }
  };

  const getModules = async () => {
    const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working
    console.log("bingo");
    const response = await fetch("http://localhost:3001/api/modules", {
      method: "GET",
      headers: {
        Authorization: `Zoho-oauthtoken ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("MODULES:", data);
      // Handle success, maybe redirect or show a success message
    } else {
      console.error("Failed to submit job");
      // Handle error
    }
  };
  const getJobModule = async () => {
    const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working
    console.log("bingo");
    const response = await fetch("http://localhost:3001/api/modulesJob", {
      method: "GET",
      headers: {
        Authorization: `Zoho-oauthtoken ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("JOB MODULE:", data);
      // Handle success, maybe redirect or show a success message
    } else {
      console.error("Failed to submit job");
      // Handle error
    }
  };

  const getCandidateModule = async () => {
    const accessToken = getAccessTokenFromCookie(); // Ensure you have this function exported and working
    console.log("bingo");
    const response = await fetch("http://localhost:3001/api/modulesCandidate", {
      method: "GET",
      headers: {
        Authorization: `Zoho-oauthtoken ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("CANDIDATE MODULE:", data);
      // Handle success, maybe redirect or show a success message
    } else {
      console.error("Failed to submit job");
      // Handle error
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2>Submit a New Job</h2>
      <Box>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MDButton onClick={submitJob}>Submit Job to Zoho Recruit</MDButton>
          <MDButton onClick={getModules}>
            Get Modules from Zoho Recruit
          </MDButton>
          <MDButton onClick={getJobModule}>
            Get JobOpening from Zoho Recruit
          </MDButton>
          <MDButton onClick={getCandidateModule}>
            Get Candidate from Zoho Recruit
          </MDButton>
          <MDButton onClick={submitEditJob}>EDIT Job</MDButton>
          <MDButton onClick={submitCandidate}>Submit Candidate</MDButton>
          <MDButton onClick={associateCandidate}>Associate Candidate</MDButton>
        </div>
      </Box>
    </DashboardLayout>
  );
};

export default SubmitJob;
