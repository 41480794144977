// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import { useAuth0 } from "@auth0/auth0-react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";
import useFetchTeamMembers from "hooks/useFetchTeamMembers";
import useUpdateUserProfile from "hooks/useUpdateUserProfile";
import useFetchUserProfile from "hooks/useFetchUserProfile";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
} from "@mui/material";
import { useState } from "react";
import MDButton from "components/MDButton";

function Overview(): JSX.Element {
  const { user } = useAuth0();
  const { updateUser } = useUpdateUserProfile({ userId: user.sub });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const handleEditDialogOpen = () => setIsEditDialogOpen(true);
  const handleEditDialogClose = () => setIsEditDialogOpen(false);

  const { loadingTeams, teamMembers } = useFetchTeamMembers({
    userId: user.sub,
  });
  const {
    loadingUser,
    user: userData,
    fetchUserProfile,
  } = useFetchUserProfile({
    userId: user.sub,
  });

  console.log("userData");
  console.log(userData);

  console.log("loadingUser");
  console.log(loadingUser);

  console.log("teamMembers");
  console.log(teamMembers);

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    let dataToSubmit = {
      description: formData.get("description"),
      mobile: formData.get("mobile"),
      location: formData.get("location"),
      facebookLink: formData.get("facebook"),
      twitterLink: formData.get("twitter"),
      instagramLink: formData.get("instagram"),
      linkedinLink: formData.get("linkedin"),
    };

    const response = await updateUser(dataToSubmit);

    handleEditDialogClose();
    fetchUserProfile();
  };

  if (loadingUser || loadingTeams) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Header>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={8} sx={{ display: "flex" }}>
                <Skeleton variant="rectangular" width="100%" height={118} />
                <Skeleton variant="text" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} xl={4}>
                {/* <Skeleton variant="rectangular" width="100%" height={100} /> */}
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="40%" />
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />

      <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
        <form onSubmit={handleEditSubmit}>
          <DialogTitle>Edit Profile Info</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={userData.description}
              name="description"
            />
            <TextField
              margin="dense"
              id="mobile"
              label="Mobile"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={userData.mobile}
              name="mobile"
            />
            <TextField
              margin="dense"
              id="location"
              label="Location"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={userData.location}
              name="location"
            />
            <TextField
              margin="dense"
              id="facebook"
              label="Facebook"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={userData.facebookLink}
              name="facebook"
            />
            <TextField
              margin="dense"
              id="twitter"
              label="Twitter"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={userData.twitterLink}
              name="twitter"
            />
            <TextField
              margin="dense"
              id="instagram"
              label="Instagram"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={userData.instagramLink}
              name="instagram"
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleEditDialogClose}>Cancel</MDButton>
            <MDButton type="submit">Save</MDButton>
          </DialogActions>
        </form>
      </Dialog>

      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} md={8} xl={8} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description={userData.description}
                info={{
                  fullName: userData.name,
                  mobile: userData.mobile,
                  email: userData.email,
                  location: userData.location,
                }}
                social={[
                  {
                    link: userData.facebookLink,
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: userData.twitterLink,
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: userData.instagramLink,
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{
                  route: "",
                  tooltip: "Edit Profile",
                  onClick: handleEditDialogOpen,
                }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={2}>
              {teamMembers.teamMembers ? (
                <ProfilesList
                  title="My Team"
                  profiles={teamMembers.teamMembers}
                  shadow={false}
                />
              ) : (
                <ProfilesList
                  title="You are not on a team"
                  profiles={[]}
                  shadow={false}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Job Posts
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Current Active Job Roles
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={microsoft}
                label="Microsoft"
                title="Engineering Manager"
                description="Description example"
                action={{
                  type: "internal",
                  route: "/jobs/1",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={microsoft}
                label="Microsoft"
                title="Python Developer"
                description="Description example"
                action={{
                  type: "internal",
                  route: "/jobs/1",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={microsoft}
                label="Microsoft"
                title="Front End Engineer"
                description="Description example"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={microsoft}
                label="Microsoft"
                title=".NET Lead"
                description="Description example"
                action={{
                  type: "internal",
                  route: "/jobs/1",
                  color: "info",
                  label: "view project",
                }}
                authors={[{ image: team1, name: "Elena Morison" }]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
