// @mui material components
// import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
// import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// React imports
import React, { useCallback, useEffect, useState } from "react";
import { useWizardContext } from "context/AddJobWizard";
import StepNavigation from "../components/StepNavigation";
import {
  Grid,
  InputLabel,
  IconButton,
  Autocomplete,
  TextField,
  SelectChangeEvent,
} from "@mui/material";

function ClearanceAndScreening({ onNext, onBack, activeStep }): JSX.Element {
  const { jobPostData } = useWizardContext();

  const [localData, setLocalData] = useState({
    clearanceLevel: "",
    screeningQuestions: [""],
  });

  useEffect(() => {
    setLocalData({
      clearanceLevel: jobPostData.clearanceLevel || "",
      screeningQuestions: jobPostData.screeningQuestions || [""],
    });
  }, [jobPostData]);

  const handleClearanceChange = (event: SelectChangeEvent<string>) => {
    setLocalData({
      ...localData,
      clearanceLevel: event.target.value,
    });
  };

  // Handle change in each screening question
  const handleQuestionChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedQuestions = [...localData.screeningQuestions];
    updatedQuestions[index] = event.target.value;
    setLocalData({ ...localData, screeningQuestions: updatedQuestions });
  };

  const addQuestion = () => {
    setLocalData({
      ...localData,
      screeningQuestions: [...localData.screeningQuestions, ""],
    });
  };

  const removeQuestion = (index: number) => {
    const updatedQuestions = [...localData.screeningQuestions];
    updatedQuestions.splice(index, 1);
    setLocalData({ ...localData, screeningQuestions: updatedQuestions });
  };

  const handleLocalBack = useCallback(() => {
    onBack(localData);
  }, [onBack, localData]);

  const handleLocalNext = useCallback(() => {
    onNext(localData);
  }, [onNext, localData]);

  const [clearanceOptions, setClearanceOptions] = useState([
    "Public Trust",
    "Secret",
    "Top Secret",
    "TS/SCI",
    "TS/SCI w/ CI Poly",
    "TS/SCI w/ FSP",
  ]);

  const handleAddClearance = (newClearance) => {
    setClearanceOptions([...clearanceOptions, newClearance]);
  };

  console.log("LOCAL DATA", localData);

  return (
    <MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={clearanceOptions}
              value={localData.clearanceLevel}
              onChange={(event, newValue) => {
                if (newValue && !clearanceOptions.includes(newValue)) {
                  handleAddClearance(newValue);
                }
                setLocalData({
                  ...localData,
                  clearanceLevel: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Clearance Level" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <MDTypography variant="h6">Screening Questions</MDTypography>
          </Grid>
          {localData.screeningQuestions.map((question, index) => (
            <Grid item xs={12} key={index}>
              <MDBox display="flex" alignItems="center">
                <MDInput
                  type="text"
                  name={`screeningQuestion${index}`}
                  label={`Question ${index + 1}`}
                  value={question}
                  onChange={(e) => handleQuestionChange(index, e)}
                  fullWidth
                />
                <IconButton onClick={() => removeQuestion(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </MDBox>
            </Grid>
          ))}
          <Grid item xs={12}>
            <IconButton onClick={addQuestion}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
        <StepNavigation
          activeStep={activeStep}
          handleLocalNext={handleLocalNext}
          onBack={handleLocalBack}
        />
      </MDBox>
    </MDBox>
  );
}

export default ClearanceAndScreening;
